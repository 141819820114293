import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StarIcon from 'material-ui/svg-icons/toggle/star';

const styles = () => ({
  extendedIcon: {
    color: 'inherit',
    verticalAlign: 'center'
  }
});

const YellowStar = ({ classes: { extendedIcon } }) => <StarIcon color="secondary" className={extendedIcon} />;

YellowStar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(YellowStar);
