import React from 'react';
import PropTypes from 'prop-types';

const FoundResearchTitle = ({ record }) => <span>Edital: {record ? `"${record.name}"` : ''}</span>;

FoundResearchTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
};

FoundResearchTitle.defaultProps = {
  record: null
};

export default FoundResearchTitle;
