import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';

const FoundResearchTitle = () => (
  <Breadcrumb size="big">
    <Breadcrumb.Section>Configurações</Breadcrumb.Section>
    <Breadcrumb.Divider />
    <Breadcrumb.Section active>Editais Scrapped</Breadcrumb.Section>
  </Breadcrumb>
);

export default FoundResearchTitle;
