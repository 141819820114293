import React, { Component } from 'react';
import { Dropdown, Input, Button, Grid, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TagsSelect from '../../common/elements/TagsSelect';

const RULE_OPTIONS = [
  // { key: 'terms', value: 'terms', text: 'Possuem o Trecho Textual:' },
  { key: 'any_fomentation_type', value: 'any_fomentation_type', text: 'Tipo de Fomento:' },
  {
    key: 'any_fomentation_opportunity',
    value: 'any_fomentation_opportunity',
    text: 'Abrangência:'
  },
  { key: 'all_tags', value: 'all_tags', text: 'Palavras-chave:' },
  // { key: 'any_tag', value: 'any_tag', text: 'Possuem a/ou a(s) tag(s):' },
  { key: 'all_great_areas', value: 'all_great_areas', text: 'Grandes Áreas do Conhecimento:' },
  // { key: 'any_great_area', value: 'any_great_area', text: 'Pertencem a pelo menos uma das Grandes Áreas:' },
  { key: 'all_areas', value: 'all_areas', text: 'Área do Conhecimento:' },
  // { key: 'any_area', value: 'any_great_area', text: 'Pertencem a/ou a(s) área(s):' },
  { key: 'all_sub_areas', value: 'all_sub_areas', text: 'Subáreas do Conhecimento:' },
  // { key: 'any_sub_area', value: 'any_sub_area', text: 'Pertencem a/ou a(s) subárea(s):' },
  { key: 'any_state_tag', value: 'any_state_tag', text: 'Exclusividade para os Estados:' }
];

const removeButton = (disabled, removeCallback) => {
  if (disabled) {
    return '';
  }
  return <Button color="gray" style={{ float: 'right', margin: '0.5em' }} icon="trash" onClick={removeCallback} />;
};

const elementByRule = (accountHash, rule, ruleValueOnChange) => {
  if (rule.type === 'all_tags' || rule.type === 'any_tag')
    return (
      <TagsSelect
        placeholder="Palavras-chave"
        onChange={ruleValueOnChange}
        tagType="tags"
        accountHash={accountHash}
        multiple
        value={rule.value}
      />
    );
  if (rule.type === 'any_fomentation_type')
    return (
      <TagsSelect
        placeholder="Tipos de Fomento"
        onChange={ruleValueOnChange}
        tagType="fomentation_types"
        accountHash={accountHash}
        multiple
        value={rule.value}
      />
    );
  if (rule.type === 'any_fomentation_opportunity')
    return (
      <TagsSelect
        placeholder="Oportunidades de Fomento"
        onChange={ruleValueOnChange}
        tagType="fomentation_opportunities"
        accountHash={accountHash}
        multiple
        value={rule.value}
      />
    );
  if (rule.type === 'any_state_tag')
    return (
      <TagsSelect
        placeholder="Estados"
        onChange={ruleValueOnChange}
        tagType="state_tags"
        accountHash={accountHash}
        multiple
        value={rule.value}
      />
    );
  if (rule.type === 'all_great_areas' || rule.type === 'any_great_area')
    return (
      <TagsSelect
        onChange={ruleValueOnChange}
        tagType="great_areas"
        accountHash={accountHash}
        placeholder="Grandes Áreas"
        multiple
        value={rule.value}
      />
    );
  if (rule.type === 'all_sub_areas' || rule.type === 'any_sub_area')
    return (
      <TagsSelect
        onChange={ruleValueOnChange}
        tagType="sub_areas"
        accountHash={accountHash}
        multiple
        value={rule.value}
        placeholder="Sub Áreas"
      />
    );
  if (rule.type === 'all_areas' || rule.type === 'any_area')
    return (
      <TagsSelect
        placeholder="Áreas"
        onChange={ruleValueOnChange}
        tagType="areas"
        accountHash={accountHash}
        multiple
        value={rule.value}
      />
    );
  if (rule.type === 'terms')
    return (
      <Input
        onChange={ruleValueOnChange}
        placeholder="Preencher termos de busca"
        tagType="tags"
        accountHash={accountHash}
        multiple
        value={rule.value}
      />
    );
  return '';
};

const SearchFilterRule = (props) => {
  const { rule, removeCallback, disabled, ruleTypeOnChange, accountHash, ruleValueOnChange } = props;

  return (
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column width={7}>
          <Popup
            trigger={
              <Dropdown
                value={rule.type}
                onChange={ruleTypeOnChange}
                clearable
                selection
                search
                placeholder="Regra do filtro"
                options={RULE_OPTIONS}
                style={{ margin: '0.5em' }}
              />
            }
            content="Selecione uma regra para adicionar um novo filtro."
            open={!(rule && rule.type)}
            position="left center"
          />
        </Grid.Column>
        <Grid.Column width={7}>{elementByRule(accountHash, rule, ruleValueOnChange)}</Grid.Column>
        <Grid.Column width={2}>{removeButton(disabled, removeCallback)}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SearchFilterRule.propTypes = {
  rule: PropTypes.object.isRequired
};

export default SearchFilterRule;
