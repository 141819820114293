import React, { Component } from 'react';

import { Card, Menu } from 'semantic-ui-react';

import { CREATE, GET_LIST, DELETE } from 'admin-on-rest';

import restClient from '../utils/restClient';

class StageCompletionUsersCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableUsers: [],
      responsibleUserIds: []
    };

    this.setStageResponsible = this.setStageResponsible.bind(this);
    this.getResponsibleUsers = this.getResponsibleUsers.bind(this);
    this.getAvailableUsers = this.getAvailableUsers.bind(this);
  }

  async componentWillMount() {
    this.getResponsibleUsers();
    this.getAvailableUsers();
  }

  async getResponsibleUsers() {
    await restClient(GET_LIST, `${this.props.location.pathname}/responsible_users`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'created_at', order: 'ASC' }
    })
      .then((response) => {
        this.setState({
          responsibleUserIds: response.data
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  async getAvailableUsers() {
    await restClient(GET_LIST, `${this.props.location.pathname}/available_users`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'created_at', order: 'ASC' }
    })
      .then((response) => {
        this.setState({
          availableUsers: response.data
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  async setStageResponsible(event, data) {
    const { id } = data;

    if (this.state.responsibleUserIds.includes(id)) {
      this.removeResponsible(id);
    } else {
      this.sendNewResponsible(id);
    }
  }

  async sendNewResponsible(userId) {
    await restClient(CREATE, `${this.props.location.pathname}/responsible_users`, { data: { userId } })
      .then(() => {
        this.getResponsibleUsers();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  async removeResponsible(userId) {
    await restClient(DELETE, `${this.props.location.pathname}/responsible_users`, { id: userId })
      .then(() => {
        this.getResponsibleUsers();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    const { availableUsers, responsibleUserIds } = this.state;

    return (
      <Card style={{ marginTop: '3em' }} fluid>
        <Card.Content>
          <Card.Header>Responsável pela etapa:</Card.Header>
        </Card.Content>
        <Card.Content>
          <Menu size="huge" color="teal" vertical secondary widths={availableUsers.length || 1}>
            {availableUsers.map((availableUser) => (
              <Menu.Item
                key={availableUser.id}
                name={`  ${availableUser.fullName}`}
                id={availableUser.id}
                icon="user"
                active={responsibleUserIds.includes(availableUser.id)}
                onClick={this.setStageResponsible}
                style={{ textAlign: 'left' }}
              />
            ))}
          </Menu>
        </Card.Content>
      </Card>
    );
  }
}

export default StageCompletionUsersCard;
