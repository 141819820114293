import React, { useState, useEffect } from 'react';
import { Dropdown, Input, Button, Container, Divider, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SearchFilterRule from './rule';

const LOGIC_OPTIONS = [
  { key: 'and', value: 'and', text: 'E' },
  { key: 'or', value: 'or', text: 'OU' }
];

const emptyRule = {
  type: null,
  value: null
};

const SearchFilterComposer = (props) => {
  const { name: pName, rules: pRules, actionCallback, accountHash, buttonLabel } = props;

  const [name, setName] = useState(pName);
  const [logicOperator, setLogicOperator] = useState('and');
  const [rules, setRules] = useState(pRules);

  useEffect(() => {
    setName(pName);
    setRules(pRules);
  }, [pName, pRules]);

  const addSearchRule = () => {
    setRules([
      ...rules,
      {
        type: null,
        value: null
      }
    ]);
  };

  const changeName = (event, { value }) => {
    setName(value);
  };

  const changeLogicOperator = (event, { value }) => {
    setLogicOperator(value);
  };

  const isNameBlank = () => {
    return !name || !name.trim();
  };

  const updateRuleType = (index, type) => {
    rules[index] = {
      ...rules[index],
      type: type || null
    };
    setRules([...rules]);
  };

  const updateRuleValue = (index, value) => {
    rules[index].value = value;
    setRules([...rules]);
  };

  const removeRule = (removeIndex) => {
    setRules(rules.filter((item, index) => removeIndex !== index));
  };

  return (
    <div>
      <Container text style={{ margin: '5em' }}>
        <Popup
          trigger={<Input value={name} label="Nome do Filtro:" placeholder="Filtro A" fluid onChange={changeName} />}
          content="Escolha um nome para seu filtro."
          open={isNameBlank()}
          position="top center"
        />
      </Container>

      <Container text style={{ margin: '5em' }}>
        Buscar editais com:
        {rules.map((rule, index) => (
          <div key={index.toString()}>
            <SearchFilterRule
              accountHash={accountHash}
              disabled={index === 0 || index < rules.length - 1}
              rule={rule}
              removeCallback={() => removeRule(index)}
              ruleTypeOnChange={(event, data) => updateRuleType(index, data.value)}
              ruleValueOnChange={(event, data) => updateRuleValue(index, data.value)}
            />
            <Divider horizontal>
              <Dropdown
                selection
                placeholder="E"
                options={LOGIC_OPTIONS}
                onChange={changeLogicOperator}
                value={logicOperator}
              />
            </Divider>
            {rules.length === index + 1 ? (
              <Button disabled={!rule.value} onClick={addSearchRule} icon="add" primary />
            ) : (
              ''
            )}
          </div>
        ))}
      </Container>
      <Container text textAlign="center">
        <Button
          style={{ margin: '2em', backgroundColor: '#5FCBCF' }}
          size="big"
          primary
          onClick={() => actionCallback(name, rules, logicOperator)}
          disabled={isNameBlank()}>
          {buttonLabel}
        </Button>
      </Container>
    </div>
  );
};

SearchFilterComposer.propTypes = {
  actionCallback: PropTypes.func.isRequired,
  accountHash: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array
};

SearchFilterComposer.defaultProps = {
  buttonLabel: '',
  name: '',
  rules: [emptyRule]
};

export default SearchFilterComposer;
