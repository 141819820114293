import React from 'react';

import { Breadcrumb, Card } from 'semantic-ui-react';

import apiClient from '../common/utils/apiClient';

import SearchFilterComposer from './common/composer';

export const SearchFiltersCreate = (props) => {
  const {
    match: {
      params: { accountHash }
    },
    history: { push }
  } = props;

  const createFilter = async (name, rules, logicOperator) => {
    const response = await apiClient.authenticatedPost({
      resource: `${accountHash}/search_filters`,
      body: {
        name,
        rules: {
          [logicOperator]: rules.filter((rule) => rule.value !== null)
        }
      }
    });

    if (response) {
      push(`/${accountHash}/search_filters`);
    }
  };

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <Breadcrumb size="big">
            <Breadcrumb.Section>Busca de Editais</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>Filtros</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>Adicionar</Breadcrumb.Section>
          </Breadcrumb>
        </Card.Header>
        <Card.Description>
          <div>
            <SearchFilterComposer
              accountHash={accountHash}
              buttonLabel="Cadastrar Filtro"
              actionCallback={createFilter}
            />
          </div>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default SearchFiltersCreate;
