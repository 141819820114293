import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { apiClient } from '../utils/apiClient';

const MettzerGrantedButton = (props) => {
  const { record, resource } = props;

  const [granted, setGranted] = useState(record.granted);

  const handleClick = async () => {
    const apiCall = granted
      ? apiClient.authenticatedClient().delete(`${resource}/pipeline_stage/${record.id}/won`)
      : apiClient.authenticatedClient().post(`${resource}/pipeline_stage`, {
          id: record.id,
          pipelineStage: 'won'
        });

    apiCall
      .then(() => {
        setGranted(!granted);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Button style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }} onClick={handleClick} floated="right">
      <Icon name={granted ? 'smile' : 'circle outline'} />
      Ganho
    </Button>
  );
};

MettzerGrantedButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string.isRequired
};

MettzerGrantedButton.defaultProps = {
  record: { granted: false }
};

export default MettzerGrantedButton;
