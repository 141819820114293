import React from 'react';
import { List, Datagrid, TextField } from 'admin-on-rest';

export const ModulesList = props => (
  <List title="Lista de Módulos" {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
