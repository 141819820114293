import React, { Component } from 'react';

import { Icon, Flag } from 'semantic-ui-react';

import { GET_LIST } from 'admin-on-rest';

import restClient from '../../common/utils/restClient';

const iconNameMapping = {
  Internacional: 'world',
  'Colaboração Internacional': 'handshake outline'
};

class ResearchNationalTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: []
    };

    this.getTags = this.getTags.bind(this);
  }

  async componentWillMount() {
    this.getTags();
  }

  async getTags() {
    await restClient(GET_LIST, `${this.props.basePath}/${this.props.researchId}/tags`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'created_at', order: 'ASC' }
    })
      .then((response) => {
        // console.log(response);
        this.setState({
          tags: response.data
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    // console.log(this.state);
    const { tags } = this.state;
    return tags.map((tag) =>
      tag.type === 'fomentation_opp' ? (
        tag.name === 'Nacional' ? (
          <Flag title={tag.name} key={tag.id} name="br" size="small" />
        ) : (
          <Icon title={tag.name} key={tag.id} name={iconNameMapping[tag.name]} size="small" />
        )
      ) : (
        ''
      )
    );
  }
}

export default ResearchNationalTags;
