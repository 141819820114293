import React from 'react';

import {
  UPDATE,
  Edit,
  DisabledInput,
  TextInput,
  FormTab,
  FileInput,
  FileField,
  SelectArrayInput,
  ReferenceArrayInput,
  NumberInput
} from 'admin-on-rest';

import RichTextInput from 'aor-rich-text-input';
import { MettzerTabbedForm } from '../common/elements/TabbedForm';

const ProjectTitle = ({ record }) => <span>Projeto: {record ? `${record.name}` : ''}</span>;

export const ProjectEdit = (props) => (
  <Edit title={<ProjectTitle />} {...props}>
    {(permissions) => (
      <MettzerTabbedForm
        history={props.history}
        redirectResource={`${permissions.currentAccount.id}/ongoing_researches`}
        event={UPDATE}
        crudUpdate={props.crudUpdate}>
        <FormTab label="Informações Gerais">
          <DisabledInput source="id" />
          <TextInput label="Nome do Projeto de Pesquisa" source="name" />
          <ReferenceArrayInput
            label="advisors"
            source="advisorsIds"
            reference={`${permissions.currentAccount.id}/users:ongoing_researches:advisors`}
            allowEmpty>
            <SelectArrayInput optionText="fullName" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Orientando(s)"
            source="researchersIds"
            reference={`${permissions.currentAccount.id}/users:ongoing_researches:researchers`}
            allowEmpty>
            <SelectArrayInput optionText="fullName" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Demais membros da equipe"
            source="assistantIds"
            reference={`${permissions.currentAccount.id}/users:ongoing_researches:assistants`}
            allowEmpty>
            <SelectArrayInput optionText="fullName" />
          </ReferenceArrayInput>
          <NumberInput source="value" label="Valor da Bolsa" />
          <RichTextInput label="Descrição do Projeto" source="description" />
        </FormTab>
        <FormTab label="Arquivos">
          <FileInput
            source="files"
            label="Arquivos do Edital"
            accept="application/pdf"
            placeholder={<p>Arraste os arquivos desejados aqui.</p>}
            multiple>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </MettzerTabbedForm>
    )}
  </Edit>
);
