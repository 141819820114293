import React from 'react';

import {
  ReferenceArrayInput,
  SelectArrayInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'admin-on-rest';

export const UsersCreate = props => (
  <Create title="Cadastro de Usuário" {...props}>
    {permissions => (
      <SimpleForm redirect="list">
        <ReferenceArrayInput
          label="Titulo(s)"
          source="degreesIds"
          reference={`${permissions.currentAccount.id}/degrees`}
          allowEmpty>
          <SelectArrayInput optionText="initials" />
        </ReferenceArrayInput>
        <TextInput label="Nome Completo do Usuário" source="fullName" />
        <TextInput label="ID Mettzer" source="externalId" />
        <TextInput label="E-mail" source="email" />
        <SelectArrayInput
          label="Perfis"
          source="types"
          choices={[
            { id: 'MODAFOCA', name: 'Modafoca' },
            { id: 'normal', name: 'Normal' }
          ]}
        />
        <ReferenceInput
          label="Conta Atual"
          source="accountId"
          reference={`${permissions.currentAccount.id}/accounts`}
          allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    )}
  </Create>
);
