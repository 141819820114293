import React, { useEffect, useState } from 'react';
import { Toolbar, ToolbarGroup, ToolbarSeparator } from 'material-ui/Toolbar';
import { Dropdown, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import muiThemeable from 'material-ui/styles/muiThemeable';
import compose from 'recompose/compose';
import { useTranslation } from 'react-i18next';
import LogoutButton from './LogoutButton';
import { getPermissions, userWithAccess } from './common/utils/permissions-utils';
import { anyPublicResearchesPermissions, anyOngoingResearchesPermissions } from './permissions-list/index';
import hasPermission from './has-permission';
import ButtonSubscribe from './trial/button-subscribe';

const MettzerToolbar = () => {
  const [permissions, setPermissions] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const { t } = useTranslation('app-bar');

  useEffect(() => {
    setPermissions(getPermissions());
  }, []);

  useEffect(() => {
    if (!permissions) return;
    const { currentAccount } = permissions;
    if (!currentAccount) return;
    setSubscriptionStatus(currentAccount.subscriptionStatus);
  }, [permissions]);

  const isTrial = () => subscriptionStatus === 'TRIAL' || subscriptionStatus === 'TRIAL_EXPIRED';

  if (!permissions || !permissions.currentAccount) return <></>;

  const { currentAccount } = permissions;
  const { id } = currentAccount;

  return (
    <Toolbar style={{ background: '#3B3B3B' }}>
      <ToolbarGroup>
        <Link to="/">
          <img
            src="https://s3.sa-east-1.amazonaws.com/mettzer.com/img/logo-white-font.png"
            style={{ width: 145, margin: 5, cursor: 'pointer', height: 40 }}
            alt="Mettzer"
          />
        </Link>
        <ToolbarSeparator style={{ backgroundColor: '#00D6DC', marginRight: '1rem' }} />
        {/* }<FlatButton style={{ margin: '10px', color: '#00D6DC' }}>Dashboard</FlatButton> */}

        {subscriptionStatus === 'blocked' && (
          <span style={{ margin: 15, color: '#00D6DC' }}>
            <Icon name="warning sign" /> Verifique o status de sua conta com a equipe Mettzer!
          </span>
        )}

        {userWithAccess(currentAccount) && hasPermission(permissions, anyPublicResearchesPermissions) && (
          <Link style={{ color: 'rgb(0, 214, 220)' }} to={`/${id}/public_researches`}>
            <Icon name="search" /> {t('oportunities')}
          </Link>
        )}

        {userWithAccess(currentAccount) && hasPermission(permissions, anyOngoingResearchesPermissions) && (
          <Link style={{ color: 'rgb(0, 214, 220)', marginLeft: '1rem' }} to={`/${id}/ongoing_researches`}>
            <Icon name="tasks" /> Gestão de Projetos
          </Link>
        )}

        {userWithAccess(currentAccount) && (
          <Dropdown
            trigger={
              <span style={{ color: '#00D6DC' }}>
                <Icon name="edit" /> Editor
              </span>
            }
            pointing
            className="link item"
            style={{ margin: 15, color: '#00D6DC' }}>
            <Dropdown.Menu>
              <Dropdown.Item>
                <a href={`${process.env.REACT_APP_EDITOR_URL}/#/app`} style={{ color: '#000000' }}>
                  Meus Trabalhos
                </a>
              </Dropdown.Item>
              <Dropdown.Item>
                <a href={`${process.env.REACT_APP_EDITOR_URL}/#/app/references-library`} style={{ color: '#000000' }}>
                  Biblioteca de Referências
                </a>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <a href={process.env.REACT_APP_HUB_URL} style={{ color: 'rgb(0, 214, 220)' }}>
          <Icon name="globe" /> Hub
        </a>
      </ToolbarGroup>
      <ToolbarGroup>
        {permissions.types && permissions.types.includes('MODAFOCA') && (
          <div>
            <Dropdown
              trigger={
                <span style={{ color: '#00D6DC' }}>
                  <Icon name="settings" /> Configurações
                </span>
              }
              pointing
              className="link item"
              style={{ margin: 15, color: '#00D6DC' }}>
              <Dropdown.Menu>
                <Dropdown.Header>Contas, Usuários e Permissões</Dropdown.Header>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/organizations`}>
                    Instituições
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/accounts`}>
                    Contas
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/users`}>
                    Usuários
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/trials/create`}>
                    Trials
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/modules`}>
                    Módulos
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/user_authorizations`}>
                    Autorizações de Usuários
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Header>Oportunidades</Dropdown.Header>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/found_researches`}>
                    Scrapping de Editais
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Header>Classificação de Editais</Dropdown.Header>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/featured_tags`}>
                    Tags para Destaque
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/tags`}>
                    Tags Genéricas
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/great_areas`}>
                    Grandes Áreas
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/areas`}>
                    Áreas
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/sub_areas`}>
                    Sub-Áreas
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/degree_eligilibilities`}>
                    Elegibilidade
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/age_ranges`}>
                    Faixas Etárias
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/genders`}>
                    Gêneros
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/state_tags`}>
                    Estados
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/fomentation_opportunities`}>
                    Oportunidades de Fomento
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link style={{ color: '#000000' }} to={`/${id}/fomentation_types`}>
                    Tipos de Oportunidades
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <a href={`${process.env.REACT_APP_BG_URL}?urlAlias=backoffice`} style={{ color: '#000000' }}>
                    Backoffice
                  </a>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {isTrial() && <ButtonSubscribe />}
        <LogoutButton />
      </ToolbarGroup>
    </Toolbar>
  );
};

const enhance = compose(muiThemeable());

export default enhance(MettzerToolbar);
