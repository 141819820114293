import React from 'react';

import { Create, SimpleForm, ReferenceInput, SelectInput, required, CheckboxGroupInput } from 'admin-on-rest';

export const TrialsCreate = (props) => (
  <Create title="Cadastro de Usuários em Trial" {...props}>
    {(permissions) => (
      <SimpleForm redirect="create">
        <ReferenceInput
          label="Conta"
          source="accountId"
          reference={`${permissions.currentAccount.id}/accounts`}
          validate={required}
          allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Usuário"
          source="userId"
          reference={`${permissions.currentAccount.id}/users`}
          validate={required}
          allowEmpty>
          <SelectInput optionText="email" />
        </ReferenceInput>
        <CheckboxGroupInput
          source="modules"
          choices={[
            { id: 'ongoing_researches', name: 'Gestão de Projetos' },
            { id: 'public_researches', name: 'Busca de Editais' }
          ]}
        />
      </SimpleForm>
    )}
  </Create>
);
