import React from 'react';
import { List, Datagrid, TextField, DeleteButton, ReferenceField } from 'admin-on-rest';

export const UserAuthorizationList = props => (
  <List title="Lista de User Authorizations" {...props}>
    {permissions => (
      <Datagrid>
        <TextField source="id" />
        <ReferenceField label="Conta" source="AccountId" reference={`${permissions.currentAccount.id}/accounts`}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Usuário" source="UserId" reference={`${permissions.currentAccount.id}/users`}>
          <TextField source="fullName" />
        </ReferenceField>
        <TextField label="Grupo" source="group" />
        <DeleteButton />
      </Datagrid>
    )}
  </List>
);
