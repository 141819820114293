import React from 'react';

import { Edit, SimpleForm, DisabledInput, TextInput, required } from 'admin-on-rest';

export const OrganizationsEdit = props => (
  <Edit title="Edição de Instituição" {...props}>
    <SimpleForm redirect="list">
      <DisabledInput label="ID" source="id" />
      <TextInput label="Nome da Instituição" source="name" />
      <TextInput label="Iniciais" source="initials" />
      <TextInput label="Razão Social" source="legalName" />
      <TextInput label="CNPJ" source="cnpj" validate={required} />
      <TextInput label="Email principal" source="mainEmail" />
      <TextInput label="CEP" source="cep" />
      <TextInput label="Endereço" source="address" />
    </SimpleForm>
  </Edit>
);
