import React, { useState, useEffect } from 'react';

import { Button, Icon, Form, TextArea, Card, Feed } from 'semantic-ui-react';
import { CREATE, GET_LIST } from 'admin-on-rest';

import restClient from '../utils/restClient';

const CommentsBox = ({ location: { pathname } }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const getComments = async () => {
    const response = await restClient(GET_LIST, `${pathname}/comments`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'created_at', order: 'ASC' }
    }).catch(e => {
      console.error(e);
    });

    if (response) {
      setComments(response.data);
    }
  };

  const setNewCommentValue = (_, { value }) => {
    setNewComment(value);
  };

  const sendNewComment = async () => {
    if (newComment) {
      await restClient(CREATE, `${pathname}/comments`, {
        data: {
          comment: newComment
        }
      }).catch(e => {
        console.error(e);
      });
      getComments();
    }
  };

  useEffect(() => {
    getComments();
  }, [pathname]);

  return (
    <Card style={{ marginTop: '3em' }} fluid>
      <Card.Content>
        <Card.Header>Comentários</Card.Header>
      </Card.Content>
      <Card.Content>
        {comments && comments.length ? (
          <Feed>
            {comments.map(comment => (
              <Feed.Event>
                <Feed.Label>
                  <Icon name="user" />
                </Feed.Label>
                <Feed.Content>
                  <Feed.Summary>
                    <Feed.User>{comment.user.fullName}</Feed.User>
                    <Feed.Date>{new Date(comment.createdAt).toLocaleString()}</Feed.Date>
                  </Feed.Summary>
                  <Feed.Extra text>{comment.text}</Feed.Extra>
                </Feed.Content>
              </Feed.Event>
            ))}
          </Feed>
        ) : (
          'Nenhum comentário'
        )}
      </Card.Content>
      <Card.Content extra>
        <Form onSubmit={sendNewComment}>
          <Form.Field
            onChange={setNewCommentValue}
            control={TextArea}
            label="Comentar"
            placeholder="Escreva aqui seu comentário..."
          />
          <Form.Field control={Button}>Enviar</Form.Field>
        </Form>
      </Card.Content>
    </Card>
  );
};

export default CommentsBox;
