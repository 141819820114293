import React from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  DateInput,
  NumberInput,
  TextInput,
  SelectInput,
  BooleanInput
} from 'admin-on-rest';
import RichTextInput from 'aor-rich-text-input';
import { EmbeddedArrayInput } from 'aor-embedded-array';

const FoundResearchFormFields = ({ currentAccount }) => [
  <RichTextInput label="Descrição" source="description" />,
  <RichTextInput label="Valores em Texto" source="valueInText" />,
  <RichTextInput label="Texto sobre deadline de submissão" source="deadlineText" />,
  <RichTextInput label="Texto sobre elegibilidade" source="eligibilityText" />,
  <RichTextInput label="Tipo de solicitação" source="solicitationType" />,
  <RichTextInput label="Formas de contato" source="contacts" />,
  <RichTextInput label="Fonte" source="source" />,
  <ReferenceArrayInput
    label="Grande Área"
    source="greatAreaTagIds"
    reference={`${currentAccount.id}/great_areas`}
    allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput label="Área" source="areaTagIds" reference={`${currentAccount.id}/areas`} allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput label="Sub Área" source="subAreaTagIds" reference={`${currentAccount.id}/sub_areas`} allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceInput
    label="Oportunidade de Fomento"
    source="fomentationOppTagId"
    reference={`${currentAccount.id}/fomentation_opportunities`}
    allowEmpty>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="Tipo de Oportunidade de Fomento"
    source="fomentationTypeTagId"
    reference={`${currentAccount.id}/fomentation_types`}
    allowEmpty>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <DateInput label="Data Limite para Submissão" source="submissionDeadlineDate" />,
  <DateInput label="Data de Início" source="startDate" />,
  <DateInput label="Data de Término" source="endDate" />,
  <EmbeddedArrayInput source="stages" label="Etapas do Edital">
    <TextInput label="Etapa" source="name" />
    <BooleanInput label="Etapa de submissão?" source="submission" />
    <DateInput label="Data Início" source="startDate" />
    <DateInput label="Data de Término" source="endDate" />
  </EmbeddedArrayInput>,
  <NumberInput label="Valor total do Edital (R$)" source="globalValue" />,
  <TextInput label="URL Publica" source="publicUri" />,
  <ReferenceArrayInput label="Tags" source="tagIds" reference={`${currentAccount.id}/tags`} allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    label="Faixa Etária"
    source="ageRangeTagIds"
    reference={`${currentAccount.id}/age_ranges`}
    allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    label="Elegibilidade"
    source="degreeTagIds"
    reference={`${currentAccount.id}/degree_eligilibilities`}
    allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput label="Estados" source="stateTagIds" reference={`${currentAccount.id}/state_tags`} allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput label="Generos" source="genderTagIds" reference={`${currentAccount.id}/genders`} allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    label="Tags para Destaque"
    source="featuredTagIds"
    reference={`${currentAccount.id}/featured_tags`}
    allowEmpty>
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>
];

FoundResearchFormFields.propTypes = {
  currentAccount: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default FoundResearchFormFields;
