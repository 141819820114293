import React from 'react';

import { CardActions } from 'material-ui/Card';

import { RefreshButton } from 'admin-on-rest';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const ResearchViewActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
  <CardActions style={cardActionStyle}>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
    <RefreshButton />
  </CardActions>
);

export default ResearchViewActions;
