import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'admin-on-rest';

export const AgeRangesList = props => (
  <List title="Lista de Tags" {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton label="" />
    </Datagrid>
  </List>
);
