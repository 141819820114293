import React from 'react';
import { Filter, ReferenceArrayInput, SelectArrayInput } from 'admin-on-rest';
import { TextInput } from '../../common/elements/TextInput';
import BooleanInput from '../../common/elements/BooleanInput';

const ResearchFilters = (props) => (
  <Filter {...props}>
    <TextInput label={null} placeholder="Buscar..." source="q" alwaysOn />
    <BooleanInput toggle label="Fluxo Contínuo?" source="continuous" />
    <BooleanInput toggle label="Mostrar Editais Inativos?" source="inactiveStage" alwaysOn />
    <BooleanInput toggle label="Apenas editais sem data de submissao" source="noSubmissionDate" alwaysOn />
    <BooleanInput toggle label="Apenas editais sem valores" source="noValue" alwaysOn />
    <ReferenceArrayInput perPage={7} label="Tags" source="tagIds" reference={`${props.resource.split('/')[0]}/tags`}>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      perPage={7}
      label="Áreas"
      source="areaTagIds"
      reference={`${props.resource.split('/')[0]}/areas`}>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Grandes Áreas"
      source="greatAreaTagIds"
      perPage={7}
      reference={`${props.resource.split('/')[0]}/great_areas`}>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Subáreas"
      source="subAreaTagIds"
      perPage={7}
      reference={`${props.resource.split('/')[0]}/sub_areas`}>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  </Filter>
);

export default ResearchFilters;
