import React from 'react';
import Button from '@material-ui/core/Button';
import BOClient from '../common/utils/backofficeClient';

const ButtonSubscribe = () => {
  const trackEvent = (event) => BOClient.trackEvent({ event });

  return (
    <a
      href={`${process.env.REACT_APP_EDITOR_URL}/#/subscribe`}
      onClick={() => trackEvent('university_subscribe-button-clicked')}>
      <Button variant="contained" className="button button-orange" style={{ marginRight: '30px' }}>
        Assine agora
      </Button>
    </a>
  );
};

export default ButtonSubscribe;
