import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './i18n';
import WhatsappButton from './common/whatsappButton';

ReactDOM.render(
  <Suspense fallback="">
    <App />
    <WhatsappButton />
  </Suspense>,
  document.getElementById('root')
);

registerServiceWorker();
