import React from 'react';
import { ReferenceInput, SelectInput } from 'admin-on-rest';

/*
  We have to use this weird pattern of
  (permissions, props) => (React.Component)(props)
  in order to use form fields as child components.
  
  Reason is unknown, but speculated to be permissions/auth providers' pattern
*/

const SearchFiltersFilter = (permissions, { resource }) => (
  <ReferenceInput
    label="researchFilterInput"
    source="savedFilterIds"
    perPage={7}
    reference={`${resource.split('/')[0]}/search_filters`}
    alwaysOn>
    <SelectInput optionText="name" />
  </ReferenceInput>
);
/*
This is how the PropTypes should be declared

SearchFiltersFilter.propTypes = {
  permissions: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  resource: PropTypes.string.isRequired
};

*/

export default SearchFiltersFilter;
