import React from 'react';
import { List } from 'admin-on-rest';
import FoundResearchTitle from './title';
import FoundResearchActions from './actions';
import ResearchFilters from './filters';
import FoundResearchGrid from './grid';

const FoundResearchList = (props) => (
  <List
    {...props}
    actions={<FoundResearchActions {...props} />}
    title={<FoundResearchTitle {...props} />}
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<ResearchFilters />}>
    <FoundResearchGrid />
  </List>
);

export default FoundResearchList;
