import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, DeleteButton } from 'admin-on-rest';

export const SubAreasList = props => (
  <List title="Lista de Sub Áreas" {...props}>
    {permissions => (
      <Datagrid>
        <TextField source="id" />
        <TextField label="Sub Área" source="name" />
        <ReferenceField label="Área" source="TagId" reference={`${permissions.currentAccount.id}/areas`}>
          <TextField source="name" />
        </ReferenceField>
        <EditButton label="" />
        <DeleteButton />
      </Datagrid>
    )}
  </List>
);
