import React from 'react';

import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  required
} from 'admin-on-rest';

export const AccountsEdit = props => (
  <Edit title="Cadastro de Contas" {...props}>
    {permissions => (
      <SimpleForm redirect="list">
        <TextInput label="Nome da Conta" source="name" />
        <ReferenceInput
          label="Instituicao"
          source="OrganizationId"
          reference={`${permissions.currentAccount.id}/organizations`}
          allowEmpty>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <RadioButtonGroupInput
          source="subscriptionStatus"
          optionText="name"
          optionValue="value"
          choices={[
            { value: 'active', name: 'Ativa' },
            { value: 'blocked', name: 'Bloqueada' }
          ]}
          validate={required}
          allowEmpty
          required
        />
      </SimpleForm>
    )}
  </Edit>
);
