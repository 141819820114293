import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-router-dom';
import { Button, Label, Card, Icon } from 'semantic-ui-react';
import InceptionTextField from '../../common/elements/InceptionTextField';

const FoundResearchGrid = ({ ids, data, basePath }) => (
  <div style={{ margin: '1em' }}>
    {ids.map(id => (
      <Card fluid>
        <Card.Content>
          <Button as={Link} to={`${basePath}/${encodeURIComponent(data[id].id)}/delete`} floated="right" icon>
            <Icon name="trash" />
          </Button>
          <div>
            <Button as={Link} to={`${basePath}/${encodeURIComponent(data[id].id)}`} floated="right" icon>
              <Icon name="edit" />
            </Button>
          </div>
          <Card.Header>
            {(data[id].pipelineStage === 'public' && <p style={{ color: 'green', fontSize: '16px' }}>PUBLICADO</p>) ||
              (data[id].pipelineStage === 'inactive' && <p style={{ color: 'grey', fontSize: '16px' }}>INATIVO</p>) || (
                <p style={{ color: 'blue', fontSize: '16px' }}>NOVO</p>
              )}
            {`Edital ${data[id].name}`}
          </Card.Header>
          <Card.Meta>
            <InceptionTextField record={data[id]} source="orgOwner.name" />
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          {data[id].tagNames &&
            data[id].tagNames.map(tag => (
              <Label style={{ marginLeft: 20, marginTop: 10 }} as="a" tag>
                {`${tag.name}`}
              </Label>
            ))}
        </Card.Content>
      </Card>
    ))}
  </div>
);

FoundResearchGrid.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
  ids: PropTypes.array
};

FoundResearchGrid.defaultProps = {
  basePath: '',
  data: {},
  ids: []
};

export default FoundResearchGrid;
