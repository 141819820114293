import React from 'react';
import PropTypes from 'prop-types';
import { CardActions } from 'material-ui/Card';
import { DeleteButton, ListButton, RefreshButton } from 'admin-on-rest';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const FoundResearchEditActions = ({ basePath, data }) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath} />
    <DeleteButton basePath={basePath} record={data} />
    <RefreshButton />
  </CardActions>
);

FoundResearchEditActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object
};

FoundResearchEditActions.defaultProps = {
  basePath: '',
  data: {}
};

export default FoundResearchEditActions;
