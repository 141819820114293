import React, { Component } from 'react';

import { Button, Modal } from 'semantic-ui-react';

import ResearchFinanceItemsTable from './ResearchFinanceItemsTable';
import ResearchStatistic from './ResearchStatistic';

class ResearchFinanceItemsModal extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      research: this.props.research,
      researchProject: this.props.researchProject,
      basePath: this.props.basePath,
      update: true
    };

    this.updateVar.bind(this);
  }

  updateVar() {
    const { update } = this.state;
    this.setState({
      update: !update
    });
  }

  render() {
    const { basePath, research, researchProject, update } = this.state;

    return (
      <Modal
        size="fullscreen"
        trigger={<Button style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }} floated="right" icon="dollar sign" />}
        centered
        closeIcon>
        <Modal.Header>
          Registros Financeiros - {researchProject ? `Projeto ${researchProject.name}` : `Edital ${research.name}`}
          <ResearchStatistic
            type="difference"
            floated="right"
            style={{ marginRight: '3em' }}
            resourcePath={`${basePath}/${research.id}/finance_items`}
            size="mini"
            update={update}
            color="green"
            text="Saldo"
            filter={{ type: null, researchProjectId: researchProject && researchProject.id }}
          />
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <ResearchFinanceItemsTable
              researchProject={researchProject}
              research={research}
              basePath={basePath}
              type="budget"
              header="Entrada"
              update={this.updateVar.bind(this)}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Content image>
          <Modal.Description>
            <ResearchFinanceItemsTable
              researchProject={researchProject}
              research={research}
              basePath={basePath}
              type="expense"
              header="Saída"
              update={this.updateVar.bind(this)}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ResearchFinanceItemsModal;
