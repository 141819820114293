import React from 'react';

import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'admin-on-rest';

export const SubAreasCreate = props => (
  <Create title="Cadastro de Sub Áreas" {...props}>
    {permissions => (
      <SimpleForm redirect="list">
        <TextInput label="Nome da Sub Área" source="name" />
        <ReferenceInput
          label="Área"
          source="TagId"
          reference={`${permissions.currentAccount.id}/areas`}
          validate={required}
          allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    )}
  </Create>
);
