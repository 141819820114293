import React from 'react';
import PropTypes from 'prop-types';
import { CreateButton, RefreshButton } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const FoundResearchActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
  <CardActions style={cardActionStyle}>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
    <CreateButton basePath={basePath} label="register" />
    <RefreshButton />
  </CardActions>
);

FoundResearchActions.propTypes = {
  resource: PropTypes.string,
  filters: PropTypes.object,
  displayedFilters: PropTypes.object,
  filterValues: PropTypes.object,
  showFilter: PropTypes.func,
  basePath: PropTypes.string
};

FoundResearchActions.defaultProps = {
  resource: '',
  filters: {},
  displayedFilters: {},
  filterValues: {},
  showFilter: () => {},
  basePath: ''
};

export default FoundResearchActions;
