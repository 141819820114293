import React from 'react';
import { List } from 'admin-on-rest';
import YellowStar from '../common/elements/YellowStar';
import ResearchFilters from '../common/public-research/filter/index';
import ResearchGrid from '../common/public-research/grid';
import ResearchViewActions from '../common/public-research/view-actions';

export const FavoriteResearchTitle = () => (
  <span>
    <span>
      <YellowStar /> Editais Favoritos
    </span>
  </span>
);

export const FavoriteResearchList = (props) => (
  <List
    {...props}
    title={<FavoriteResearchTitle {...props} />}
    actions={<ResearchViewActions />}
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<ResearchFilters {...props} />}>
    {(permissions) => <ResearchGrid permissions={permissions} {...props} />}
  </List>
);
