import React from 'react';

import { Create, SimpleForm, TextInput, required } from 'admin-on-rest';

export const OrganizationsCreate = props => (
  <Create title="Cadastro de Instituição" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome da Instituição" source="name" />
      <TextInput label="Iniciais" source="initials" />
      <TextInput label="Razão Social" source="legalName" />
      <TextInput label="CNPJ" source="cnpj" validate={required} />
      <TextInput label="Email principal" source="mainEmail" />
      <TextInput label="País" source="country" />
      <TextInput label="CEP" source="cep" />
      <TextInput label="Endereço" source="address" />
    </SimpleForm>
  </Create>
);
