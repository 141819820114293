import React from 'react';

import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  RadioButtonGroupInput
} from 'admin-on-rest';

export const AccountsCreate = props => (
  <Create title="Cadastro de Contas" {...props}>
    {permissions => (
      <SimpleForm redirect="list">
        <TextInput label="Nome da Conta" source="name" />
        <ReferenceInput
          label="Instituição"
          source="OrganizationId"
          reference={`${permissions.currentAccount.id}/organizations`}
          allowEmpty
          perPage={100}>
          <AutocompleteInput optionText="name" elStyle={{ width: '100px' }} />
        </ReferenceInput>
        <RadioButtonGroupInput
          source="subscriptionStatus"
          optionText="name"
          optionValue="value"
          choices={[
            { value: 'active', name: 'Ativa' },
            { value: 'blocked', name: 'Bloqueada' }
          ]}
          validate={required}
          allowEmpty
        />
      </SimpleForm>
    )}
  </Create>
);
