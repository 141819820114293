import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb, Icon, Card, Button } from 'semantic-ui-react';

import { Filter, TextInput, List, CreateButton, RefreshButton } from 'admin-on-rest';

import { CardActions } from 'material-ui/Card';

import ResearchOwner from './components/ResearchOwner';
import ResearchNationalTags from './components/ResearchNationalTags';
import ResearchFinanceItemsModal from './components/ResearchFinanceItemsModal';
import ResearchReportModal from './components/ResearchReportModal';
import ResearchListSummary from './components/ResearchListSummary';
import ResearchProjectsList from './components/ResearchProjectsList';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const emptyList = {
  width: '100%',
  minHeight: 150,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top'
};

const ResearchActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
  <CardActions style={cardActionStyle}>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
    <CreateButton basePath={basePath} label="register" />
    <RefreshButton label="update" />
  </CardActions>
);

const ResearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscar" source="q" alwaysOn />
  </Filter>
);

const CardActionButtons = ({ basePath, record, permissions }) => (
  <div>
    <Button
      style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
      as={Link}
      to={`${basePath}/${encodeURIComponent(record.id)}/delete`}
      floated="right"
      icon="trash"
    />
    <Button
      style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
      as={Link}
      to={`${basePath}/${encodeURIComponent(record.id)}`}
      floated="right"
      icon="edit"
    />
    <ResearchFinanceItemsModal basePath={basePath} research={record} researchId={record.id} />
    <ResearchReportModal basePath={basePath} research={record} researchId={record.id} />
    {/* {record.lastZip ? (
      <Button
        floated="right"
        style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
        href={record.lastZip}
        icon="download"
      />
    ) : null} */}
  </div>
);

const ResearchGrid = ({ permissions, ids, data, basePath }) => (
  <div style={{ margin: '1em' }}>
    {ids.map((id) => (
      <div key={id}>
        <Card style={{ marginTop: '3em' }} fluid>
          <Card.Content>
            {(permissions.roles.includes('ongoing_researches:admins') ||
              permissions.roles.includes('ongoing_researches:coordinators')) && (
              <CardActionButtons basePath={basePath} record={data[id]} permissions={permissions} />
            )}
            <Card.Header>
              <ResearchNationalTags basePath={basePath} researchId={id} /> {`${data[id].name}`}
            </Card.Header>
            <Card.Meta>
              Responsável: <ResearchOwner basePath={basePath} researchId={id} />
            </Card.Meta>
          </Card.Content>
          {data[id].files && data[id].files.length > 0 ? (
            <Card.Content extra>
              Documentos do Edital:
              {data[id].files.map((file) => (
                <a style={{ marginLeft: '5px' }} href={file.src} title={file.title} target="_blank" rel="noreferrer">
                  <Icon size="large" name="file alternate" />
                </a>
              ))}
            </Card.Content>
          ) : (
            <div style={{ fontSize: 10 }} />
          )}
          <Card.Content>
            {data[id].researchProjects && data[id].researchProjects.length > 0 ? (
              <div>
                <ResearchProjectsList
                  data={data[id].researchProjects}
                  stages={data[id].stages}
                  files={data[id].files}
                  basePath={basePath}
                  research={data[id]}
                  permissions={permissions}
                />
              </div>
            ) : (
              <div style={emptyList}>Nenhum projeto cadastrado.</div>
            )}
          </Card.Content>
        </Card>
      </div>
    ))}
  </div>
);
ResearchGrid.defaultProps = {
  permissions: {},
  data: {},
  ids: []
};

const OngoingResearchTitle = (props) => (
  <Breadcrumb size="big">
    <Breadcrumb.Section>Gestão de Projetos</Breadcrumb.Section>
  </Breadcrumb>
);

export const ResearchList = (props) => (
  <div>
    <ResearchListSummary basePath={props.resource} />
    <List
      title={<OngoingResearchTitle />}
      {...props}
      actions={<ResearchActions />}
      sort={{ field: 'created_at', order: 'DESC' }}
      filters={<ResearchFilter />}>
      {(permissions) => <ResearchGrid permissions={permissions} />}
    </List>
  </div>
);
