import React from 'react';
import {
  Edit,
  DisabledInput,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  RadioButtonGroupInput,
  BooleanInput
} from 'admin-on-rest';
import FoundResearchTitle from './title';
import FoundResearchEditActions from './actions';
import FoundResearchFormFields from '../components/form-fields';

const FoundResearchEdit = props => (
  <Edit actions={<FoundResearchEditActions />} title={<FoundResearchTitle />} {...props}>
    {permissions => (
      <SimpleForm redirect="list">
        <DisabledInput source="id" label="Identificador" />
        <BooleanInput label="Fluxo Contínuo?" source="continuous" />
        <RadioButtonGroupInput
          source="pipelineStage"
          label="Estágio"
          choices={[
            { id: 'inactive', name: 'Inativo' },
            { id: 'found', name: 'Scrapped' },
            { id: 'public', name: 'Público' }
          ]}
        />
        <TextInput label="Nome do Edital" source="name" />
        <ReferenceInput
          label="Instituição"
          source="OrganizationId"
          reference={`${permissions.currentAccount.id}/organizations`}
          allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        {FoundResearchFormFields(permissions)}
      </SimpleForm>
    )}
  </Edit>
);

export default FoundResearchEdit;
