import React, { Component } from 'react';

import { Loader } from 'semantic-ui-react';

import { GET_ONE } from 'admin-on-rest';

import restClient from '../../common/utils/restClient';

class ResearchOwner extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      owner: {}
    };

    this.getOwnerByResearchId = this.getOwnerByResearchId.bind(this);
  }

  async componentWillMount() {
    this.getOwnerByResearchId();
  }

  async getOwnerByResearchId() {
    await restClient(GET_ONE, `${this.props.basePath}/${this.props.researchId}/owner`, {
      id: ''
    })
      .then(response => {
        this.setState({
          owner: response.data
        });
      })
      .catch(e => {
        console.error(e);
      });
  }

  render() {
    // console.log(this.state);
    const { owner } = this.state;
    return owner.fullName ? owner.fullName : <Loader active inline size="mini" />;
  }
}

export default ResearchOwner;
