import React from 'react';
import PropTypes from 'prop-types';
import { Filter, ReferenceArrayInput, SelectArrayInput, DateInput } from 'admin-on-rest';
import SearchFiltersFilter from './filter';
import { TextInput } from '../../elements/TextInput';
import BooleanInput from '../../elements/BooleanInput';
import { getPermissions } from '../../utils/permissions-utils';

const ResearchFilters = (props) => {
  const permissions = getPermissions();
  const { resource } = props;

  return (
    <Filter {...props} elStyle={{ marginTop: '4em' }}>
      <ReferenceArrayInput perPage={7} label="Tags" source="tagIds" reference={`${resource.split('/')[0]}/tags`}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput perPage={7} label="Areas" source="areaTagIds" reference={`${resource.split('/')[0]}/areas`}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Grande Areas"
        source="greatAreaTagIds"
        perPage={7}
        reference={`${resource.split('/')[0]}/great_areas`}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Sub Areas"
        source="subAreaTagIds"
        perPage={7}
        reference={`${resource.split('/')[0]}/sub_areas`}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <DateInput label="Data de Submissão antes de:" source="beforeSubmissionDate" alwaysOn />
      <DateInput label="Data de Submissão depois de:" source="afterSubmissionDate" alwaysOn />
      <BooleanInput toggle label="Fluxo Contínuo?" source="continuous" alwaysOn />
      <TextInput
        label={null}
        elStyle={{ textAlign: 'left', width: '450px' }}
        placeholder="Buscar..."
        source="q"
        alwaysOn
      />
      {SearchFiltersFilter(permissions, { resource }) /* see comments inside */}
    </Filter>
  );
};

ResearchFilters.propTypes = {
  resource: PropTypes.string.isRequired
};

export default ResearchFilters;
