import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { apiClient } from '../utils/apiClient';

const MettzerSubmittedButton = (props) => {
  const { record, resource } = props;
  const [submitted, setSubmitted] = useState(record.submitted);

  const handleClick = async () => {
    const apiCall = submitted
      ? apiClient.authenticatedClient().delete(`${resource}/pipeline_stage/${record.id}/submitted`)
      : apiClient.authenticatedClient().post(`${resource}/pipeline_stage`, {
          id: record.id,
          pipelineStage: 'submitted'
        });

    apiCall
      .then(() => {
        setSubmitted(!submitted);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Button style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }} onClick={handleClick} floated="right">
      <Icon name={submitted ? 'check circle outline' : 'circle outline'} />
      Submetido
    </Button>
  );
};

MettzerSubmittedButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string.isRequired
};

MettzerSubmittedButton.defaultProps = {
  record: { submitted: false }
};

export default MettzerSubmittedButton;
