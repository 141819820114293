import React, { Component } from 'react';

import { Table } from 'semantic-ui-react';

import { GET_LIST } from 'admin-on-rest';
import restClient from '../../common/utils/restClient';

class ResearchProjectMemberTableCell extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      memberLevel: this.props.memberLevel,
      members: [],
      researchProjectId: this.props.researchProjectId,
      researchId: this.props.researchId,
      basePath: this.props.basePath
    };

    this.getMembers = this.getMembers.bind(this);
  }

  async componentWillMount() {
    this.getMembers();
  }

  async getMembers() {
    const { basePath, memberLevel, researchId, researchProjectId } = this.state;
    await restClient(GET_LIST, `${basePath}/${researchId}/research_projects/${researchProjectId}/members`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'created_at', order: 'ASC' },
      filter: {
        memberLevel
      }
    })
      .then(response => {
        this.setState({
          members: response.data
        });
      })
      .catch(e => {
        console.error(e);
      });
  }

  buildDisplayName(membersArray) {
    return membersArray.map(member => member.displayName).join('\n');
  }

  render() {
    const { members } = this.state;

    return (
      <Table.Cell style={{ whiteSpace: 'pre' }} title={members && this.buildDisplayName(members)}>
        {members && this.buildDisplayName(members)}
      </Table.Cell>
    );
  }
}

export default ResearchProjectMemberTableCell;
