import React from 'react';
import { List, Datagrid, TextField, DeleteButton, EditButton } from 'admin-on-rest';

export const DegreesList = props => (
  <List title="Lista de Titulações" {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="key" />
      <TextField source="initials" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
