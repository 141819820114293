import { jsonServerRestClient, fetchUtils } from 'admin-on-rest';
import TokenUtils from './token-utils';

const { REACT_APP_METTZER_TOKEN_HEADER, REACT_APP_API_HOST } = process.env;

const httpClient = (url, options = {}) => {
  const { headers = new Headers({ Accept: 'application/json' }) } = options;

  const token = TokenUtils.getJWTToken();
  // console.log(token);
  headers.set(REACT_APP_METTZER_TOKEN_HEADER, token);
  /// / console.log(options.headers);
  return fetchUtils.fetchJson(url, { ...options, headers });
};

const restClient = jsonServerRestClient(REACT_APP_API_HOST, httpClient);

export default restClient;
