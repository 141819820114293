const PERMISSIONS_KEY = 'mtt_user_permissions';
const ACCOUNT_KEY = 'mtt_acc';
const DEFAULT_PERMISSIONS = {
  default: true,
  currentAccount: {},
  types: [],
  roles: []
};
const allowedStatus = ['active', 'TRIAL', 'PAID'];

export const deletePermissions = () => {
  localStorage.removeItem(ACCOUNT_KEY);
  localStorage.removeItem(PERMISSIONS_KEY);
};

export const setPermissions = (permissions) => {
  const { currentAccount: { id } = {}, types = [] } = permissions;

  const accountId = !id && types.includes('MODAFOCA') ? 'MASTER' : id;
  localStorage.setItem(ACCOUNT_KEY, accountId);
  localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
};

export const getPermissions = () => {
  const strPermissions = localStorage.getItem(PERMISSIONS_KEY);
  try {
    return strPermissions ? JSON.parse(strPermissions) : DEFAULT_PERMISSIONS;
  } catch (err) {
    console.error('Error getting permissions from local storage', err);
    localStorage.removeItem(PERMISSIONS_KEY);
    return DEFAULT_PERMISSIONS;
  }
};

export const userWithAccess = ({ subscriptionStatus }) => allowedStatus.includes(subscriptionStatus);

export const getAccountId = () => localStorage.getItem(ACCOUNT_KEY);

export default {
  setPermissions,
  getPermissions,
  deletePermissions,
  getAccountId
};
