import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Label, Card, Icon } from 'semantic-ui-react';

import InceptionTextField from '../elements/InceptionTextField';

import MettzerSendToNextStageButton from '../elements/MettzerSendToNextStageButton';
import MettzerStarButton from '../elements/MettzerStarButton';
import MettzerSubmittedButton from '../elements/MettzerSubmittedButton';
import MettzerGrantedButton from '../elements/MettzerGrantedButton';

import hasPermission from '../../has-permission';
import { createOngoingResearchesPermissions } from '../../permissions-list/ongoing-researches';

const getNextSubmissionDeadlineDate = (researchData) => {
  let date = null;

  if (researchData.stagesCount > 0) {
    date =
      researchData.stagesDeadlineDates.filter((stageDate) => new Date(stageDate) > new Date())[0] ||
      researchData.stagesDeadlineDates[researchData.stagesDeadlineDates.length - 1];
  } else {
    date = researchData.submissionDeadlineDate;
  }

  return (
    date && (
      <Label as="a" color="teal" ribbon>
        Submissão: {new Date(date).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}
      </Label>
    )
  );
};

const CardActionButtons = ({ basePath, record, permissions }) => (
  <div>
    <Button
      style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
      as={Link}
      to={`${basePath}/${encodeURIComponent(record.id)}/show`}
      floated="right"
      icon>
      <Icon name="search" />
    </Button>
    <MettzerGrantedButton record={record} resource={basePath} />
    <MettzerSubmittedButton record={record} resource={basePath} />
    <MettzerStarButton record={record} resource={basePath} />
    {hasPermission(permissions, createOngoingResearchesPermissions) && (
      <MettzerSendToNextStageButton linkTo={`${basePath}/${encodeURIComponent(record.id)}`} />
    )}
  </div>
);

const ResearchGrid = ({ permissions, ids, data, basePath }) => (
  <div style={{ margin: '1em' }}>
    {data[ids[0]].total && data[ids[0]].total > 0 && (
      <div style={{ margin: '1em', textAlign: 'left' }}>
        {' '}
        Resultado da busca:{' '}
        <strong>
          {`${data[ids[0]].total}`} {data[ids[0]].total > 1 ? 'editais encontrados' : 'edital encontrado'}
        </strong>
      </div>
    )}
    <div style={{ margin: '1em', textAlign: 'left' }}>
      {' '}
      Potencial de captação estimado:{' '}
      <strong>
        {data[ids[0]].totalValue && data[ids[0]].totalValue > 0
          ? data[ids[0]].totalValue.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL'
            })
          : '-'}
      </strong>
    </div>
    {ids.map((id) => (
      <Card key={id} style={{ marginTop: '3em' }} fluid>
        <Card.Content>
          {getNextSubmissionDeadlineDate(data[id])}
          <CardActionButtons basePath={basePath} record={data[id]} permissions={permissions} />
          <Card.Header style={data[id].submissionDeadlineDate ? { marginTop: '0.5em' } : {}}>
            <Link to={`${basePath}/${encodeURIComponent(data[id].id)}/show`}>{`${data[id].name}`}</Link>
          </Card.Header>
          <Card.Meta>
            <InceptionTextField record={data[id]} source="orgOwner.name" />
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          {data[id].tagNames &&
            data[id].tagNames.map((tag) => (
              <Label key={tag.name} style={{ marginLeft: 20, marginTop: 10 }} as="a" tag>
                {`${tag.name}`}
              </Label>
            ))}
        </Card.Content>
      </Card>
    ))}
  </div>
);
ResearchGrid.defaultProps = {
  permissions: {},
  data: {},
  ids: []
};

export default ResearchGrid;
