import React, { useState, useEffect } from 'react';

import { Breadcrumb, Button, Card, Container, Input, Table, Icon, Confirm } from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import apiClient from '../common/utils/apiClient';

export const SearchFiltersList = (props) => {
  const {
    match: {
      params: { accountHash }
    }
  } = props;

  const [searchFilters, setSearchFilters] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterToDelete, setFilterToDelete] = useState({});

  const updateSearchFilters = async () => {
    const response = await apiClient.authenticatedGet({
      resource: `${accountHash}/search_filters`,
      queryParams: {
        q: searchTerm
      }
    });

    setSearchFilters(response.data);
  };

  const updateSearch = (_, data) => {
    setSearchTerm(data.value);
  };

  const confirmDelete = (filter) => {
    setFilterToDelete(filter);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => setOpenConfirmDelete(false);

  const deleteFilter = async () => {
    if (filterToDelete && filterToDelete.id) {
      await apiClient.authenticatedDelete({
        resource: `${accountHash}/search_filters/${filterToDelete.id}`
      });

      setOpenConfirmDelete(false);
      await updateSearchFilters();
    }
  };

  useEffect(() => {
    updateSearchFilters();
  }, [accountHash, searchTerm]);

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <Breadcrumb size="big">
            <Link style={{ color: '#000000' }} to="public_researches">
              <Breadcrumb.Section>
                <Icon name="reply" />
                Busca de Editais
              </Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>Filtros</Breadcrumb.Section>
          </Breadcrumb>
          <Input icon="search" placeholder="Nome do Filtro..." style={{ float: 'right' }} onChange={updateSearch} />
        </Card.Header>
        <Card.Description>
          {searchFilters && searchFilters.length ? (
            <Table striped style={{ marginTop: '5em' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>
                    <Link style={{ color: '#FFFFFF' }} to="search_filters/create">
                      <Button style={{ backgroundColor: '#5FCBCF', float: 'right' }} size="medium" primary>
                        Adicionar filtro
                      </Button>
                    </Link>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {searchFilters.map((filter) => (
                  <Table.Row key={filter.id}>
                    <Table.Cell>{filter.name}</Table.Cell>
                    <Table.Cell>
                      <Button
                        floated="right"
                        style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
                        icon="trash"
                        onClick={() => confirmDelete(filter)}
                      />
                      <Link to={`/${accountHash}/search_filters/${filter.id}`}>
                        <Button floated="right" style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }} icon="edit" />
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <div>
              <Container text textAlign="center" style={{ marginTop: '2em' }}>
                Você não possui filtros cadastrados, configure o seu primeiro filtro.
              </Container>
              <Container text textAlign="center">
                <Link style={{ color: '#FFFFFF' }} to="search_filters/create">
                  <Button style={{ margin: '2em', backgroundColor: '#00D6DC' }} size="big" primary>
                    Adicionar filtro
                  </Button>
                </Link>
              </Container>
            </div>
          )}
          <Confirm
            open={openConfirmDelete}
            onCancel={closeConfirmDelete}
            cancelButton="Cancelar"
            confirmButton="OK"
            onConfirm={deleteFilter}
            content={`Você tem certeza que deseja deletar o filtro ${filterToDelete.name} ?`}
          />
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default SearchFiltersList;
