import React from 'react';
import { List, Datagrid, TextField, EditButton, Filter, TextInput } from 'admin-on-rest';

function OrganizationsFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Busca" source="q" alwaysOn />
    </Filter>
  );
}

// eslint-disable-next-line import/prefer-default-export
export function OrganizationsList(props) {
  return (
    <List title="Instituições" filters={<OrganizationsFilter />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField label="Nome da Instituição" source="name" />
        <TextField label="Iniciais" source="initials" />
        <TextField label="Razão Social" source="legalName" />
        <TextField label="CNPJ" source="cnpj" />
        <TextField label="Email principal" source="mainEmail" />
        <TextField label="Endereço" source="address" />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
}
