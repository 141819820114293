/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Item, Divider, Label } from 'semantic-ui-react';

import apiClient from '../utils/apiClient';

const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

const getNextDate = (dates) => {
  const posteriorDates = dates.filter((d) => d > new Date());

  return posteriorDates.length ? new Date(Math.min.apply(null, posteriorDates)) : dates[dates.length - 1];
};

function getNextSubmissionDeadlineDate(research) {
  const { submissionDeadlineDate, stages = [] } = research;

  const submissionStagesDeadlines = stages.filter((stage) => stage.submission).map((stage) => new Date(stage.endDate));

  const submissionDeadlines = submissionDeadlineDate
    ? [new Date(submissionDeadlineDate), ...submissionStagesDeadlines]
    : submissionStagesDeadlines;

  return getNextDate(submissionDeadlines);
}

const PublicResearchInfo = ({ record, resource }) => {
  useEffect(() => {
    apiClient
      .authenticatedClient()
      .post(`${resource}/pipeline_stage`, {
        id: record.id,
        pipelineStage: 'viewed'
      })
      .catch();
  }, []);

  const nextSubmissionDeadlineDate = getNextSubmissionDeadlineDate(record);
  return (
    <div>
      <Item.Group style={{ margin: '5em', marginRight: '5em', marginBottom: '100px', marginTop: '100px' }}>
        <Item style={{ marginTop: '3em' }}>
          <Item.Content>
            <Item.Header>Instituição</Item.Header>
            <Item.Description>{record.orgOwner.name}</Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Item.Content>
            <Item.Header>Descrição</Item.Header>

            <Item.Description>
              <div dangerouslySetInnerHTML={{ __html: record.description }} />
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        {nextSubmissionDeadlineDate ? (
          <div>
            <Item>
              <Item.Content>
                <Item.Header>Data Limite para Submissão</Item.Header>

                <Item.Description>
                  {nextSubmissionDeadlineDate.toLocaleDateString('pt-BR', dateOptions)}
                </Item.Description>
              </Item.Content>
            </Item>
            <Divider />
          </div>
        ) : (
          ''
        )}
        <Item>
          <Item.Content>
            <Item.Header>Valores</Item.Header>

            <Item.Description>
              <div dangerouslySetInnerHTML={{ __html: record.valueInText }} />
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Item.Content>
            <Item.Header>Elegibilidade</Item.Header>

            <Item.Description>
              <div dangerouslySetInnerHTML={{ __html: record.eligibilityText }} />
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Item.Content>
            <Item.Header>Forma de Solicitação</Item.Header>

            <Item.Description>
              <div dangerouslySetInnerHTML={{ __html: record.solicitationType }} />
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Item.Content>
            <Item.Header>Contato</Item.Header>

            <Item.Description>
              <div dangerouslySetInnerHTML={{ __html: record.contacts }} />
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Item.Content>
            <Item.Header>Mais informações</Item.Header>

            <Item.Description>
              <div dangerouslySetInnerHTML={{ __html: record.publicUri }} />
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Item.Content extra>
            {record.tags &&
              record.tags.map((tag) => (
                <Label style={{ marginLeft: 20, marginTop: 10 }} as="a" tag>
                  {`${tag.name}`}
                </Label>
              ))}
          </Item.Content>
        </Item>

        <Divider />
      </Item.Group>
    </div>
  );
};

PublicResearchInfo.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string
};

PublicResearchInfo.defaultProps = {
  record: {},
  resource: ''
};

export default PublicResearchInfo;
