import React from 'react';

import {
  Edit,
  TextInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  required,
  Toolbar,
  RadioButtonGroupInput,
  ListButton,
  RefreshButton,
  UPDATE
} from 'admin-on-rest';

import { CardActions } from 'material-ui/Card';
import RichTextInput from 'aor-rich-text-input';
import SendButton from '../common/elements/SendButton';

import MettzerTabbedForm from '../common/elements/TabbedForm';

const ResearchTitle = ({ record }) => <span>Deseja enviar o Edital para uma próxima etapa?</span>;

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const FeaturedResearchEditActions = ({ basePath, data }) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

const FeaturedResearchEditToolbar = props => (
  <Toolbar {...props}>
    <SendButton label="Enviar!" redirect="show" submitOnEnter={false} />
  </Toolbar>
);

export const FeaturedResearchEdit = props => (
  <Edit actions={<FeaturedResearchEditActions />} title={<ResearchTitle />} {...props}>
    {permissions => (
      <MettzerTabbedForm
        toolbar={<FeaturedResearchEditToolbar />}
        history={props.history}
        redirectResource={`${permissions.currentAccount.id}/ongoing_researches`}
        event={UPDATE}
        crudUpdate={props.crudUpdate}>
        <FormTab label="Informações Gerais">
          <RadioButtonGroupInput
            source="pipelineStage"
            label="Etapa"
            choices={[{ id: 'ongoing', name: 'Gestão' }]}
            validate={required}
          />
          <TextInput label="Nome do Edital" source="name" />
          <ReferenceInput
            label="Responsável"
            source="userId"
            reference={`${permissions.currentAccount.id}/users:ongoing_researches:coordinators`}
            validate={required}
            allowEmpty>
            <SelectInput optionText="fullName" />
          </ReferenceInput>
          <RichTextInput label="Descrição" source="description" />
        </FormTab>
        <FormTab label="Arquivos">
          <FileInput
            source="files"
            label="Arquivos do Edital"
            accept="application/pdf"
            placeholder={<p>Arraste os arquivos desejados aqui.</p>}
            multiple>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </MettzerTabbedForm>
    )}
  </Edit>
);
