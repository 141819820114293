import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'semantic-ui-react';

import apiClient from '../utils/apiClient';

const MettzerStarButton = (props) => {
  const { record, resource } = props;
  const [starred, setStarred] = useState(record.favorite);

  const handleClick = async () => {
    const apiCall = starred
      ? apiClient.authenticatedClient().delete(`${resource}/pipeline_stage/${record.id}/starred`)
      : apiClient.authenticatedClient().post(`${resource}/pipeline_stage`, {
          id: record.id,
          pipelineStage: 'starred'
        });

    apiCall
      .then(() => {
        setStarred(!starred);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Button
      style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
      onClick={handleClick}
      floated="right"
      icon={starred ? 'star' : 'star outline'}
    />
  );
};

MettzerStarButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string.isRequired
};

MettzerStarButton.defaultProps = {
  record: { starred: true }
};

export default MettzerStarButton;
