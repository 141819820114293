import React from 'react';
import { Admin, Resource, Delete } from 'admin-on-rest';
import restClient from './common/utils/restClient';
import addUploadCapabilities from './common/utils/addUploadCapabilities';
import routes from './routes';
import MettzerLayout from './styles/mettzerLayout';
import { Dashboard } from './dashboard';
import Auth from './auth';
import { OrganizationsList, OrganizationsCreate, OrganizationsEdit } from './organization/index';
import { AccountsList, AccountsCreate, AccountsEdit } from './account/index';
import { TrialsCreate } from './trial/index';
import { UsersList, UsersCreate, UsersEdit } from './user/index';
import { ModulesList, ModulesCreate } from './module/index';
import { UserAuthorizationList, UserAuthorizationCreate } from './user-authorization/index';
import { DegreesList, DegreesCreate, DegreesEdit } from './degree/index';
import { TagsList, TagsCreate, TagsEdit } from './tag/index';
import { AreasList, AreasCreate, AreasEdit } from './area/index';
import { GreatAreasList, GreatAreasCreate, GreatAreasEdit } from './great-area/index';
import { SubAreasList, SubAreasCreate, SubAreasEdit } from './sub-area/index';
import {
  DegreeEligibilitiesList,
  DegreeEligibilitiesCreate,
  DegreeEligibilitiesEdit
} from './degree-eligibility/index';
import { FomentationTypesList, FomentationTypesCreate, FomentationTypesEdit } from './fomentation-type/index';
import { FomentationOppsList, FomentationOppsCreate, FomentationOppsEdit } from './fomentation-opp/index';
import { AgeRangesList, AgeRangesCreate, AgeRangesEdit } from './age-range/index';
import { GendersList, GendersCreate, GendersEdit } from './gender/index';
import { FeaturedTagsList, FeaturedTagsCreate, FeaturedTagsEdit } from './featured-tag/index';
import { StateTagsList, StateTagsCreate, StateTagsEdit } from './state-tag/index';
import { ResearchList, ResearchEdit, ResearchCreate } from './research/index';
import { FoundResearchList, FoundResearchCreate, FoundResearchEdit } from './found-research/index';
import { PublicResearchList, PublicResearchEdit } from './public-research/index';
import { FavoriteResearchList, FavoriteResearchEdit } from './favorite-research/index';
import { FeaturedResearchList, FeaturedResearchEdit } from './featured-research/index';
import ResearchShowDetails from './common/public-research/details';
import { SearchPreferencesEdit } from './search-preferences/index';
import { ProjectEdit } from './project/index';
import { StageCompletionEdit, StageCompletionList } from './stage-completion/index';
import defaultMessages from './i18n/messages';
import { anyPublicResearchesPermissions, anyOngoingResearchesPermissions } from './permissions-list/index';
import hasPermission from './has-permission';
import authClient from './authClient';
import { userWithAccess } from './common/utils/permissions-utils';

const messages = {
  ptBR: defaultMessages
};

const App = () => (
  <Admin
    locale="ptBR"
    messages={messages}
    customRoutes={routes}
    dashboard={Dashboard}
    authClient={authClient}
    restClient={addUploadCapabilities(restClient)}
    appLayout={MettzerLayout}
    loginPage={Auth}
    title="Mettzer">
    {(permissions) => [
      <Resource
        name={`${permissions.currentAccount.id}/areas`}
        list={AreasList}
        create={AreasCreate}
        edit={AreasEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/great_areas`}
        list={GreatAreasList}
        create={GreatAreasCreate}
        edit={GreatAreasEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/sub_areas`}
        list={SubAreasList}
        create={SubAreasCreate}
        edit={SubAreasEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/fomentation_opportunities`}
        list={FomentationOppsList}
        create={FomentationOppsCreate}
        edit={FomentationOppsEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/fomentation_types`}
        list={FomentationTypesList}
        create={FomentationTypesCreate}
        edit={FomentationTypesEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/age_ranges`}
        list={AgeRangesList}
        create={AgeRangesCreate}
        edit={AgeRangesEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/degree_eligilibilities`}
        list={DegreeEligibilitiesList}
        create={DegreeEligibilitiesCreate}
        edit={DegreeEligibilitiesEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/genders`}
        list={GendersList}
        create={GendersCreate}
        edit={GendersEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/state_tags`}
        list={StateTagsList}
        create={StateTagsCreate}
        edit={StateTagsEdit}
        remove={Delete}
      />,
      <Resource
        name={`${permissions.currentAccount.id}/featured_tags`}
        list={FeaturedTagsList}
        create={FeaturedTagsCreate}
        edit={FeaturedTagsEdit}
        remove={Delete}
      />,

      <Resource
        name={`${permissions.currentAccount.id}/tags`}
        list={TagsList}
        create={TagsCreate}
        edit={TagsEdit}
        remove={Delete}
      />,
      ...(permissions.types.includes('MODAFOCA')
        ? [
            <Resource
              name={`${permissions.currentAccount.id}/users`}
              list={UsersList}
              create={UsersCreate}
              edit={UsersEdit}
            />,
            <Resource
              name={`${permissions.currentAccount.id}/organizations`}
              list={OrganizationsList}
              create={OrganizationsCreate}
              edit={OrganizationsEdit}
            />,
            <Resource
              name={`${permissions.currentAccount.id}/accounts`}
              list={AccountsList}
              create={AccountsCreate}
              edit={AccountsEdit}
            />,
            <Resource
              name={`${permissions.currentAccount.id}/found_researches`}
              list={FoundResearchList}
              create={FoundResearchCreate}
              edit={FoundResearchEdit}
              remove={Delete}
            />,
            <Resource name={`${permissions.currentAccount.id}/modules`} list={ModulesList} create={ModulesCreate} />,
            <Resource
              name={`${permissions.currentAccount.id}/user_authorizations`}
              list={UserAuthorizationList}
              create={UserAuthorizationCreate}
              remove={Delete}
            />,
            <Resource
              name={`${permissions.currentAccount.id}/degrees`}
              list={DegreesList}
              create={DegreesCreate}
              edit={DegreesEdit}
              remove={Delete}
            />,
            <Resource name={`${permissions.currentAccount.id}/trials`} create={TrialsCreate} />
          ]
        : ''),
      ...(userWithAccess(permissions.currentAccount) && hasPermission(permissions, anyOngoingResearchesPermissions)
        ? [
            <Resource
              name={`${permissions.currentAccount.id}/ongoing_researches`}
              list={ResearchList}
              edit={ResearchEdit}
              create={ResearchCreate}
              remove={Delete}
            />,
            <Resource name={`${permissions.currentAccount.id}/projects`} edit={ProjectEdit} />,
            <Resource
              name={`${permissions.currentAccount.id}/stage_completions`}
              edit={StageCompletionEdit}
              list={StageCompletionList}
            />,
            <Resource name={`${permissions.currentAccount.id}/users:ongoing_researches:researchers`} />,
            <Resource name={`${permissions.currentAccount.id}/users:ongoing_researches:coordinators`} />,
            <Resource name={`${permissions.currentAccount.id}/users:ongoing_researches:assistants`} />,
            <Resource name={`${permissions.currentAccount.id}/users:ongoing_researches:advisors`} />
          ]
        : ''),
      ...(userWithAccess(permissions.currentAccount) && hasPermission(permissions, anyPublicResearchesPermissions)
        ? [
            <Resource name={`${permissions.currentAccount.id}/search_filters`} />,
            <Resource name="EK/public_researches" show={ResearchShowDetails} />,
            <Resource
              name={`${permissions.currentAccount.id}/public_researches`}
              list={PublicResearchList}
              edit={PublicResearchEdit}
              show={ResearchShowDetails}
            />,
            <Resource
              name={`${permissions.currentAccount.id}/favorite_researches`}
              list={FavoriteResearchList}
              edit={FavoriteResearchEdit}
              show={ResearchShowDetails}
            />,
            <Resource
              name={`${permissions.currentAccount.id}/featured_researches`}
              list={FeaturedResearchList}
              edit={FeaturedResearchEdit}
              show={ResearchShowDetails}
            />,
            <Resource name={`${permissions.currentAccount.id}/search_preferences`} edit={SearchPreferencesEdit} />
          ]
        : '')
    ]}
  </Admin>
);

export default App;
