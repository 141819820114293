const COOKIE_JWT = 'mettzer-user-jwt';

export default {
  getJWTToken() {
    return localStorage.getItem(COOKIE_JWT);
  },

  setJWTToken(value) {
    localStorage.setItem(COOKIE_JWT, value);
  },

  deleteJWTToken() {
    localStorage.setItem(COOKIE_JWT, undefined);
  }
};
