import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const styles = {
  block: {
    margin: '1rem 0'
  }
};

const BooleanInput = (props) => {
  const { input, label, elStyle, options, toggle } = props;

  const handleToggle = (event, data) => {
    input.onChange(data.checked);
  };

  return (
    <div style={elStyle || styles.block}>
      <Checkbox
        checked={!!input.value}
        onChange={handleToggle}
        style={styles.toggle}
        label={label}
        toggle={toggle}
        {...options}
      />
    </div>
  );
};

BooleanInput.propTypes = {
  addField: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  toggle: PropTypes.bool
};

BooleanInput.defaultProps = {
  addField: true,
  options: {},
  elStyle: {},
  label: '',
  resource: '',
  source: ',',
  toggle: false
};

export default BooleanInput;
