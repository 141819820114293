import React from 'react';
import { Route } from 'react-router-dom';
import { SearchFiltersList, SearchFiltersCreate, SearchFiltersEdit } from '../search-filters/index';
import Auth from '../auth';
import { getPermissions, userWithAccess } from '../common/utils/permissions-utils';

const { currentAccount } = getPermissions();

let paths = [<Route path="/auth/:token/:redirectTo?" component={(props) => <Auth {...props} />} />];

if (userWithAccess(currentAccount)) {
  paths = [
    ...[
      <Route exact path="/:accountHash/search_filters" component={(props) => <SearchFiltersList {...props} />} />,
      <Route
        exact
        path="/:accountHash/search_filters/create"
        component={(props) => <SearchFiltersCreate {...props} />}
      />,
      <Route
        exact
        path="/:accountHash/search_filters/:filterId"
        component={(props) => <SearchFiltersEdit {...props} />}
      />
    ]
  ];
}

const routes = paths;

export default routes;
