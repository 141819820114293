import React from 'react';

import { Edit, SimpleForm, TextInput } from 'admin-on-rest';

export const DegreesEdit = props => (
  <Edit title="Cadastro de Titulações" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome da Titulação" source="name" />
      <TextInput label="Inicias" source="initials" />
      <TextInput label="key" source="key" />
    </SimpleForm>
  </Edit>
);
