import { AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'admin-on-rest';
import jwt from 'jsonwebtoken';
import TokenUtils from './common/utils/token-utils';
import { getPermissions, setPermissions, deletePermissions, userWithAccess } from './common/utils/permissions-utils';
import IntercomUtils from './common/utils/IntercomUtils';

const REGEX_TOKEN = /[\w_-]+\.[\w_-]+\.[\w_-]+/;

const { REACT_APP_BG_URL } = process.env;

const isValidToken = (token) => token && REGEX_TOKEN.test(token);

export const requestNewAuth = () => {
  TokenUtils.deleteJWTToken();
  deletePermissions();
  window.location = `${REACT_APP_BG_URL}?urlAlias=university`;
};

const permissions = () => Promise.resolve(getPermissions());

const { REACT_APP_API_HOST, REACT_APP_METTZER_TOKEN_HEADER = 'x-mettzer-rest-api-user-token' } = process.env;

const decodeUser = (token) => jwt.decode(token);

const setUserInfoOnIntegrations = (user) => {
  IntercomUtils.loadScript(user);
};

export const findPermissions = async (token) => {
  const request = new Request(`${REACT_APP_API_HOST}/permissions`, {
    method: 'GET',
    headers: new Headers({
      [REACT_APP_METTZER_TOKEN_HEADER]: token
    })
  });
  const response = await fetch(request);
  if (response.status !== 200) {
    throw new Error('Não foi possível buscar as permissões do usuário.');
  }

  return response.json();
};

const login = async (token, redirect) => {
  // console.log('Autenticando o usuário com o Token', token);
  TokenUtils.setJWTToken(token);

  const user = decodeUser(token);

  setUserInfoOnIntegrations(user);

  let err;

  const perms = await findPermissions(token).catch((e) => {
    err = e;
  });

  if (err) {
    console.error('Erro buscando permissões:', err);
    throw err;
  }

  if (perms) setPermissions(perms);

  if (redirect) window.location.hash = redirect;

  return user;
};

export const resetPermissions = async () => {
  const token = TokenUtils.getJWTToken();
  try {
    const perms = await findPermissions(token);
    if (!perms) return;
    setPermissions(perms);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const logout = () => {
  TokenUtils.deleteJWTToken();
  deletePermissions();
  window.location = `${REACT_APP_BG_URL}/logout`;
};

const error = ({ status, message }) => {
  console.log('Authentication error', status, message);
  if (status === 401 || status === 403) {
    requestNewAuth();
    return Promise.reject();
  }

  return Promise.resolve();
};

const check = async () => {
  if (isValidToken(TokenUtils.getJWTToken())) {
    const { currentAccount } = getPermissions();
    if (!userWithAccess(currentAccount)) {
      window.location.pathname = '/';
    }
    return Promise.resolve();
  }
  requestNewAuth();
  return Promise.reject();
};

const AUTH_TYPES_MAP = {
  [AUTH_GET_PERMISSIONS]: permissions,
  [AUTH_LOGIN]: login,
  [AUTH_LOGOUT]: logout,
  [AUTH_ERROR]: error,
  [AUTH_CHECK]: check
};

export default (type, params) => {
  // called when the user attempts to log in
  const typeFunc = AUTH_TYPES_MAP[type];
  if (typeFunc) {
    return typeFunc(params);
  }

  console.log('Não encontramos a função para o tipo', type);
  return new Promise((resolve) => resolve());
};
