import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, Filter, TextInput } from 'admin-on-rest';

function AccountsFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Busca" source="q" alwaysOn />
    </Filter>
  );
}

// eslint-disable-next-line import/prefer-default-export
export function AccountsList(props) {
  return (
    <List title="Lista de Contas" filters={<AccountsFilter />} {...props}>
      {permissions => (
        <Datagrid>
          <TextField source="id" />
          <TextField label="Nome" source="name" />
          <TextField label="Status" source="subscriptionStatus" />
          <ReferenceField
            label="IES"
            source="OrganizationId"
            reference={`${permissions.currentAccount.id}/organizations`}>
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
}
