import axios from 'axios';
import { requestNewAuth } from '../../authClient';
import TokenUtils from './token-utils';

const { REACT_APP_API_HOST, REACT_APP_METTZER_TOKEN_HEADER } = process.env;

export const apiClient = {
  async authenticatedGet({ resource, queryParams }) {
    try {
      const response = await this.authenticatedClient().get(resource, {
        params: queryParams
      });
      return response;
    } catch (error) {
      return handleError(error);
    }
  },

  async authenticatedPost({ resource, body }) {
    try {
      const response = await this.authenticatedClient().post(resource, body);
      return response;
    } catch (error) {
      return handleError(error);
    }
  },

  async authenticatedDelete({ resource }) {
    try {
      const response = await this.authenticatedClient().delete(resource);
      return response;
    } catch (error) {
      return handleError(error);
    }
  },

  async authenticatedPut({ resource, body }) {
    try {
      const response = await this.authenticatedClient().put(resource, body);
      return response;
    } catch (error) {
      return handleError(error);
    }
  },

  authenticatedClient() {
    return axios.create({
      baseURL: REACT_APP_API_HOST,
      timeout: 5000,
      headers: {
        [REACT_APP_METTZER_TOKEN_HEADER]: TokenUtils.getJWTToken(),
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });
  }
};

function handleError(error) {
  const {
    response: { status }
  } = error;
  if (status === 401) {
    requestNewAuth();
  }
  return { error };
}

export default apiClient;
