import React from 'react';
import { Link } from 'react-router-dom';

import { Table, Icon, Button } from 'semantic-ui-react';

import ResearchProjectMemberTableCell from './ResearchProjectMemberTableCell';
import ResearchFinanceItemsModal from './ResearchFinanceItemsModal';

const tableColumn = {};

const StatusProps = (status, startDate, endDate, prop) => {
  const today = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (status === 'completed') {
    return prop === 'color' ? { positive: true } : 'checkmark';
  }
  if (today > end) {
    return prop === 'color' ? { negative: true } : 'attention';
  }
  if (today <= end && today >= start) {
    return prop === 'color' ? { style: { backgroundColor: '#C6DAFF' } } : 'play';
  }
  if (today < start) {
    return prop === 'color' ? { style: { backgroundColor: '#ECECEC' } } : '';
  }
  return { color: 'white' };
};

const ResearchProjectsList = ({ data, stages, files, basePath, research, permissions }) => (
  <div style={{ margin: '1em' }}>
    <Table basic="very" celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell wrapperstyle={tableColumn} />
          <Table.HeaderCell title="Orientador">Orientadores</Table.HeaderCell>
          <Table.HeaderCell title="Orientandos">Orientandos</Table.HeaderCell>
          <Table.HeaderCell title="Demais membros">Demais membros</Table.HeaderCell>
          <Table.HeaderCell title="Documentos">Documentos</Table.HeaderCell>
          {stages.map((stage) => (
            <Table.HeaderCell key={stage.name} title={stage.name}>
              {stage.name}
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell> Ações </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((researchProject) => (
          <Table.Row key={research.id}>
            <Table.Cell title={researchProject.name}>{researchProject.name}</Table.Cell>
            <ResearchProjectMemberTableCell
              basePath={basePath}
              memberLevel="advisor"
              researchId={research.id}
              researchProjectId={researchProject.id}
            />
            <ResearchProjectMemberTableCell
              basePath={basePath}
              memberLevel="researcher"
              researchId={research.id}
              researchProjectId={researchProject.id}
            />
            <ResearchProjectMemberTableCell
              basePath={basePath}
              memberLevel="assistant"
              researchId={research.id}
              researchProjectId={researchProject.id}
            />
            <Table.Cell>
              {researchProject.files &&
                researchProject.files.map((file) => (
                  <a href={file.src} title={file.title} target="_blank" rel="noreferrer">
                    <Icon name="file alternate" />
                  </a>
                ))}
            </Table.Cell>
            {stages.map((stage) => (
              <Table.Cell
                key={stage.id}
                textAlign="center"
                selectable
                {...StatusProps(
                  researchProject.indexedStageCompletionsByStageId &&
                    researchProject.indexedStageCompletionsByStageId[stage.id].status,
                  stage.startDate,
                  stage.endDate,
                  'color'
                )}>
                <Link
                  to={`/${
                    permissions.currentAccount.id
                  }/stage_completions/${researchProject.indexedStageCompletionsByStageId &&
                    researchProject.indexedStageCompletionsByStageId[stage.id].id}`}>
                  <Icon
                    name={StatusProps(
                      researchProject.indexedStageCompletionsByStageId &&
                        researchProject.indexedStageCompletionsByStageId[stage.id].status,
                      stage.startDate,
                      stage.endDate,
                      'icon'
                    )}
                  />
                </Link>
              </Table.Cell>
            ))}
            <Table.Cell style={{ textAlign: 'left' }}>
              <div>
                <Button
                  style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
                  as={Link}
                  to={`/${permissions.currentAccount.id}/projects/${researchProject.id}`}
                  floated="right"
                  icon="edit"
                />
                <ResearchFinanceItemsModal
                  basePath={basePath}
                  researchProject={researchProject}
                  research={research}
                  researchId={research.id}
                />
                {/* {researchProject.lastZip ? (
                  <Button
                    floated="right"
                    style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
                    href={researchProject.lastZip}
                    icon="download"
                  />
                ) : null} */}
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          {stages.map((stage) => (
            <Table.Cell key={stage.id} title={new Date(stage.endDate).toLocaleDateString('pt-BR')}>
              {new Date(stage.endDate).toLocaleDateString('pt-BR')}
            </Table.Cell>
          ))}
          <Table.Cell />
        </Table.Row>
      </Table.Footer>
    </Table>
  </div>
);
ResearchProjectsList.defaultProps = {
  data: {},
  stages: [],
  files: [],
  research: null
};

export default ResearchProjectsList;
