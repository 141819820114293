import React from 'react';
import { EditButton, List, Datagrid, TextField, FunctionField, Filter, TextInput } from 'admin-on-rest';

function UsersFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Busca" source="q" alwaysOn />
    </Filter>
  );
}

// eslint-disable-next-line import/prefer-default-export
export function UsersList(props) {
  return (
    <List title="Lista de Usuários" filters={<UsersFilter />} {...props}>
      <Datagrid>
        <TextField source="id" label="id" />
        <FunctionField
          label="Degrees"
          render={record => (record.degrees ? record.degrees.map(degree => degree.name).join() : '')}
        />
        <TextField source="fullName" label="Nome" />
        <TextField source="email" label="E-Mail" />
        <FunctionField label="Perfis" render={record => (record.types ? record.types.join() : '')} />
        <TextField source="externalId" label="ID Editor" />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
}
