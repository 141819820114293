import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 */

const styles = {
  block: {
    margin: '1rem 0'
  }
};

export class TextInput extends Component {
  handleBlur = (eventOrValue) => {
    this.props.onBlur(eventOrValue);
    this.props.input.onBlur(eventOrValue);
  };

  handleFocus = (event) => {
    this.props.onFocus(event);
    this.props.input.onFocus(event);
  };

  handleChange = (eventOrValue) => {
    this.props.onChange(eventOrValue);
    this.props.input.onChange(eventOrValue);
  };

  render() {
    const { elStyle, input, label, meta, options, type, placeholder, transparent } = this.props;
    if (typeof meta === 'undefined') {
      throw new Error(
        "The TextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/admin-on-rest/Inputs.html#writing-your-own-input-component for details."
      );
    }
    const { touched, error } = meta;

    return (
      <div style={elStyle || styles.block}>
        <Input
          {...input}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          type={type}
          label={label}
          transparent={transparent}
          placeholder={placeholder}
          errortext={touched && error}
          style={elStyle}
          {...options}
        />
      </div>
    );
  }
}

TextInput.propTypes = {
  addField: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  transparent: PropTypes.bool
};

TextInput.defaultProps = {
  addField: true,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  label: '',
  meta: {},
  name: '',
  elStyle: {},
  resource: '',
  source: '',
  options: {},
  type: 'text',
  isRequired: false,
  placeholder: '',
  transparent: false
};

export default TextInput;
