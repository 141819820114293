import React from 'react';

import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'admin-on-rest';

export const AreasCreate = props => (
  <Create title="Cadastro de Áreas" {...props}>
    {permissions => (
      <SimpleForm redirect="list">
        <TextInput label="Nome da Área" source="name" />
        <ReferenceInput
          label="Grande Área"
          source="TagId"
          reference={`${permissions.currentAccount.id}/great_areas`}
          validate={required}
          allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    )}
  </Create>
);
