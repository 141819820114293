import axios from 'axios';
import { requestNewAuth } from '../../authClient';
import TokenUtils from './token-utils';

const { REACT_APP_BO_URL, REACT_APP_METTZER_TOKEN_HEADER } = process.env;

export const BackofficeClient = {
  async trackEvent({ event, properties }) {
    try {
      const token = TokenUtils.getJWTToken();
      const response = await axios.post(
        `${REACT_APP_BO_URL}/api/analytics/track/${event}`,
        { properties },
        {
          headers: getHeaders(token)
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      return handleError(error);
    }
  }
};

function getHeaders(token) {
  const headers = {};
  headers[REACT_APP_METTZER_TOKEN_HEADER || 'x-mettzer-rest-api-user-token'] = token;
  return headers;
}

function handleError(error) {
  const {
    response: { status }
  } = error;
  if (status === 401) {
    requestNewAuth();
  }
  console.error(error);
  return { error };
}

export default BackofficeClient;
