import React, { Component } from 'react';

import { TabbedForm } from 'admin-on-rest';

import Snackbar from 'material-ui/Snackbar';

import restClient from '../utils/restClient';
import addUploadCapabilities from '../utils/addUploadCapabilities';

const uploadableRestClient = addUploadCapabilities(restClient);

export class MettzerTabbedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoHideDuration: 3000,
      message: 'Event added to your calendar',
      open: false
    };
  }

  handleActionClick = () => {
    this.setState({
      open: false
    });
    alert('Event removed from your calendar.');
  };

  handleChangeDuration = event => {
    const { value } = event.target;
    this.setState({
      autoHideDuration: value.length > 0 ? parseInt(value, 10) : 0
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    return (
      <div>
        <TabbedForm
          {...this.props}
          save={(record, redirect) => {
            const updatedRecord = { ...record, is_approved: true };
            uploadableRestClient(this.props.event, this.props.resource, { id: record.id, data: updatedRecord })
              .then(() => {
                // console.log('updated');
                this.setState({
                  open: true,
                  message: 'Projeto atualizado com sucesso.'
                });
                this.props.history.push(`/${this.props.redirectResource}`);
              })
              .catch(e => {
                console.error(e);
                this.setState({
                  open: true,
                  message: 'Erro: Não foi possível atualizar o projeto.'
                });
              });
          }}
        />
        <Snackbar
          open={this.state.open}
          message={this.state.message}
          autoHideDuration={this.state.autoHideDuration}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    );
  }
}

export default MettzerTabbedForm;
