import React from 'react';
import { List } from 'admin-on-rest';
import { Breadcrumb } from 'semantic-ui-react';
import ResearchFilters from '../common/public-research/filter/index';
import ResearchGrid from '../common/public-research/grid';
import ResearchViewActions from '../common/public-research/view-actions';

export const PublicResearchTitle = () => (
  <Breadcrumb size="big">
    <Breadcrumb.Section>Busca de Editais</Breadcrumb.Section>
    <Breadcrumb.Divider />
    <Breadcrumb.Section active>Busca Avançada</Breadcrumb.Section>
  </Breadcrumb>
);

export const PublicResearchList = (props) => (
  <div>
    <List
      {...props}
      title={<PublicResearchTitle {...props} />}
      actions={<ResearchViewActions />}
      sort={{ field: 'created_at', order: 'DESC' }}
      filters={<ResearchFilters {...props} />}>
      {(permissions) => <ResearchGrid permissions={permissions} {...props} />}
    </List>
  </div>
);
