import {
  seePublicResearchesPermissions,
  createPublicResearchesPermissions,
  anyPublicResearchesPermissions
} from './public-researches';

import {
  seeOngoingResearchesPermissions,
  createOngoingResearchesPermissions,
  anyOngoingResearchesPermissions
} from './ongoing-researches';

const anyResearchesPermissions = [...anyPublicResearchesPermissions, ...anyOngoingResearchesPermissions];

export {
  anyResearchesPermissions,
  anyPublicResearchesPermissions,
  createPublicResearchesPermissions,
  seePublicResearchesPermissions,
  seeOngoingResearchesPermissions,
  createOngoingResearchesPermissions,
  anyOngoingResearchesPermissions
};
