import React from 'react';
import { CardActions } from 'material-ui/Card';
import { Show, WithPermission } from 'admin-on-rest';
import { Breadcrumb } from 'semantic-ui-react';
import MettzerSendToNextStageButton from '../elements/MettzerSendToNextStageButton';
import MettzerStarButton from '../elements/MettzerStarButton';
import MettzerSubmittedButton from '../elements/MettzerSubmittedButton';
import MettzerGrantedButton from '../elements/MettzerGrantedButton';

import PublicResearchInfo from '../elements/PublicResearchInfo';

import hasPermission from '../../has-permission';
import { getAccountId } from '../utils/permissions-utils';
import { createOngoingResearchesPermissions } from '../../permissions-list/ongoing-researches';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const PublicResearchShowTitle = props => (
  <Breadcrumb size="big">
    <Breadcrumb.Section>Busca de Editais</Breadcrumb.Section>
    <Breadcrumb.Divider />
    <Breadcrumb.Section active>{props.record.name}</Breadcrumb.Section>
  </Breadcrumb>
);

const PublicResearchShowActions = ({ basePath, data }) => {
  const accId = getAccountId();
  return (
    <CardActions style={cardActionStyle}>
      <div>
        {data && <MettzerGrantedButton record={data} resource={`/${accId}/public_researches`} />}
        {data && <MettzerSubmittedButton record={data} resource={`/${accId}/public_researches`} />}
        {data && <MettzerStarButton record={data} resource={`/${accId}/public_researches`} />}
        <WithPermission
          resolve={authObject => hasPermission(authObject.permissions, createOngoingResearchesPermissions)}>
          {data && basePath ? (
            <MettzerSendToNextStageButton linkTo={`/${accId}/public_researches/${encodeURIComponent(data.id)}`} />
          ) : (
            <div />
          )}
        </WithPermission>
      </div>
    </CardActions>
  );
};

const ResearchShowDetails = props => (
  <div>
    <Show actions={<PublicResearchShowActions {...props} />} title={<PublicResearchShowTitle {...props} />} {...props}>
      {permissions => <PublicResearchInfo />}
    </Show>
  </div>
);

export default ResearchShowDetails;
