import React from 'react';

import { Create, SimpleForm, ReferenceInput, SelectInput, RadioButtonGroupInput, required } from 'admin-on-rest';

export const UserAuthorizationCreate = props => (
  <Create title="Cadastro de Autorizações" {...props}>
    {permissions => (
      <SimpleForm redirect="list">
        <ReferenceInput
          label="Conta"
          source="accountId"
          reference={`${permissions.currentAccount.id}/accounts`}
          validate={required}
          allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Usuário"
          source="userId"
          reference={`${permissions.currentAccount.id}/users`}
          validate={required}
          allowEmpty>
          <SelectInput optionText="email" />
        </ReferenceInput>
        <ReferenceInput
          label="Módulo"
          source="moduleId"
          reference={`${permissions.currentAccount.id}/modules`}
          validate={required}
          allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <RadioButtonGroupInput
          source="group"
          optionText="name"
          optionValue="value"
          choices={[
            { value: 'admins', name: 'Admin' },
            { value: 'coordinators', name: 'Coordenador' },
            { value: 'advisors', name: 'Orientadores' },
            { value: 'researchers', name: 'Pesquisador' },
            { value: 'assistants', name: 'Assistentes de Pesquisa' }
          ]}
          validate={required}
          allowEmpty
        />
      </SimpleForm>
    )}
  </Create>
);
