export default {
  researchFilterInput: 'Filtro Personalizado',
  aor: {
    action: {
      delete: 'Excluir',
      show: 'Mostrar',
      list: 'Lista',
      save: 'Salvar',
      create: 'Criar',
      edit: 'Edit',
      cancel: 'Cancelar',
      refresh: 'Atualizar',
      add_filter: 'Adicionar filtro',
      remove_filter: 'Remover este filtro',
      back: 'Voltar',
      send_to_next_stage: 'Enviar!'
    },
    boolean: {
      true: 'Sim',
      false: 'Não'
    },
    page: {
      list: '%{name} Lista',
      edit: '%{name} #%{id}',
      show: '%{name} #%{id}',
      create: 'Criar %{name}',
      delete: 'Remover %{name} #%{id}',
      dashboard: 'Dashboard',
      not_found: 'Não Encontrado'
    },
    input: {
      autocomplete: {
        none: 'Nenhum'
      },
      file: {
        upload_several: 'Arraste alguns arquivos para upload, ou clique para selecionar um.',
        upload_single: 'Arraste um arquivo para upload, ou clique para selecionar um.'
      },
      image: {
        upload_several: 'Drop some pictures to upload, or click to select one.',
        upload_single: 'Drop a picture to upload, or click to select it.'
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing: 'At least one of the associated references no longer appears to be available.',
        single_missing: 'Associated reference no longer appears to be available'
      },
      embedded_array: {
        add: 'Adicionar',
        remove: 'Remover'
      }
    },
    message: {
      yes: 'Sim',
      no: 'Não',
      are_you_sure: 'Você tem certeza?',
      about: 'Sobre',
      not_found: 'Either you typed a wrong URL, or you followed a bad link.'
    },
    navigation: {
      no_results: 'Nenhum resultado encontrado',
      no_more_results: 'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
      next: 'Prox',
      prev: 'Ant'
    },
    auth: {
      username: 'Usuário',
      password: 'Senha',
      sign_in: 'Sign in',
      sign_in_error: 'Login falhou, por favor tente novamente',
      logout: 'Logout'
    },
    notification: {
      updated: 'Atualizado com sucesso!',
      created: 'Criado com sucesso!',
      deleted: 'Removido com sucesso!',
      item_doesnt_exist: 'Elemento não existe',
      http_error: 'Erro de comunicação com o servidor.',
      bad_item: 'Elemento incorreto.'
    },
    validation: {
      required: 'Obrigatório',
      minLength: 'Deve conter %{min} characteres pelo menos.',
      maxLength: 'Deve conter %{max} characteres ou menos',
      minValue: 'Deve ser pelo menos %{min}',
      maxValue: 'Deve ser %{max} ou menos',
      number: 'Deve ser um número',
      email: 'Deve conter um e-mail válido'
    }
  },
  responsible: 'Responsável',
  assistants: 'Assistente(s)',
  researchers: 'Pesquisador(es)',
  advisors: 'Orientador(es)',
  update: 'Atualizar',
  register: 'Cadastrar'
};
