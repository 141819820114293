import React, { useState, useEffect } from 'react';

import { Breadcrumb, Card } from 'semantic-ui-react';

import apiClient from '../common/utils/apiClient';

import SearchFilterComposer from './common/composer';

export const SearchFiltersEdit = (props) => {
  const {
    match: {
      params: { filterId, accountHash }
    },
    history: { push }
  } = props;

  const [filter, setFilter] = useState({});

  const getFilter = async () => {
    const response = await apiClient.authenticatedGet({
      resource: `${accountHash}/search_filters/${filterId}`
    });

    setFilter(response.data);
  };

  useEffect(() => {
    getFilter();
  }, [filterId]);

  const updateFilter = async (name, rules, logicOperator) => {
    const response = await apiClient.authenticatedPut({
      resource: `${accountHash}/search_filters/${filterId}`,
      body: {
        name,
        rules: {
          [logicOperator]: rules.filter((rule) => rule.value !== null)
        }
      }
    });

    if (response.status === 200) {
      push(`/${accountHash}/search_filters`);
    }
  };

  const { id, name, rule = { and: [] } } = filter;

  const logicOperator = Object.keys(rule)[0];
  const rules = rule[logicOperator];

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <Breadcrumb size="big">
            <Breadcrumb.Section>Busca de Editais</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>Filtros</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>Editar</Breadcrumb.Section>
          </Breadcrumb>
        </Card.Header>
        <Card.Description>
          {id && (
            <SearchFilterComposer
              logicOperator={logicOperator}
              name={name}
              rules={rules}
              accountHash={accountHash}
              buttonLabel="Atualizar Filtro"
              actionCallback={updateFilter}
            />
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default SearchFiltersEdit;
