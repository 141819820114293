import React from 'react';

import {
  Edit,
  DisabledInput,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  DateInput,
  FileInput,
  FileField,
  BooleanInput,
  required,
  SelectArrayInput,
  ReferenceArrayInput,
  RadioButtonGroupInput,
  NumberInput,
  CheckboxGroupInput,
  DeleteButton,
  ListButton,
  RefreshButton
} from 'admin-on-rest';

import { CardActions } from 'material-ui/Card';

import RichTextInput from 'aor-rich-text-input';

import { EmbeddedArrayInput } from 'aor-embedded-array';

const ResearchTitle = ({ record }) => <span>Edital: {record ? `"${record.name}"` : ''}</span>;

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const ResearchEditActions = ({ basePath, data }) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath} />
    <DeleteButton basePath={basePath} record={data} />
    <RefreshButton />
  </CardActions>
);

export const ResearchEdit = (props) => (
  <Edit actions={<ResearchEditActions />} title={<ResearchTitle />} {...props}>
    {(permissions) => (
      <TabbedForm redirect="list">
        <FormTab label="Informações Gerais">
          <DisabledInput source="id" label="Identificador" />
          <TextInput label="Nome do Edital" source="name" />
          <ReferenceInput
            label="responsible"
            source="userId"
            reference={`${permissions.currentAccount.id}/users:ongoing_researches:coordinators`}
            validate={required}
            allowEmpty>
            <SelectInput optionText="fullName" />
          </ReferenceInput>
          <RadioButtonGroupInput
            source="type"
            optionText="name"
            label="Tipo"
            optionValue="value"
            choices={[
              { value: 'internal', name: 'Interno' },
              { value: 'external', name: 'Externo' }
            ]}
          />
          <NumberInput label="Valor Global" source="globalValue" />
          <CheckboxGroupInput
            label=""
            source="tags"
            choices={[
              { id: 'Nacional', name: 'Nacional' },
              { id: 'Internacional', name: 'Internacional' },
              { id: 'Colaboração Internacional', name: 'Colaboração Internacional' }
            ]}
          />
          <RichTextInput label="Descrição" source="description" />
        </FormTab>
        <FormTab label="Etapas do Edital">
          <EmbeddedArrayInput source="stages" label="">
            <TextInput label="Etapa" source="name" />
            <BooleanInput label="Etapa de submissão?" source="submission" />
            <DateInput label="Data Início" source="startDate" />
            <DateInput label="Data de Término" source="endDate" />
          </EmbeddedArrayInput>
        </FormTab>
        <FormTab label="Projetos de Pesquisa">
          <EmbeddedArrayInput source="researchProjects" label="">
            <TextInput label="Nome do Projeto" source="name" />
            <ReferenceArrayInput
              label="advisors"
              source="advisorsIds"
              reference={`${permissions.currentAccount.id}/users:ongoing_researches:advisors`}
              allowEmpty>
              <SelectArrayInput optionText="fullName" />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="researchers"
              source="researchersIds"
              reference={`${permissions.currentAccount.id}/users:ongoing_researches:researchers`}
              allowEmpty>
              <SelectArrayInput optionText="fullName" />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="assistants"
              source="assistantIds"
              reference={`${permissions.currentAccount.id}/users:ongoing_researches:assistants`}
              allowEmpty>
              <SelectArrayInput optionText="fullName" />
            </ReferenceArrayInput>
            <NumberInput source="value" label="Valor da Bolsa" />
            <RichTextInput label="Descrição" source="description" />
          </EmbeddedArrayInput>
        </FormTab>
        <FormTab label="Arquivos">
          <FileInput
            source="files"
            label="Arquivos do Edital"
            accept="application/pdf"
            placeholder={<p>Arraste os arquivos desejados aqui.</p>}
            multiple>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    )}
  </Edit>
);

export default {};
