import React from 'react';
import {
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  FileInput,
  TabbedForm,
  FormTab,
  FileField
} from 'admin-on-rest';
import FoundResearchCreateActions from './actions';
import FoundResearchFormFields from '../components/form-fields';

const FoundResearchCreate = props => (
  <Create actions={<FoundResearchCreateActions />} title="Cadastrar um Edital" {...props}>
    {permissions => (
      <TabbedForm redirect="list">
        <FormTab label="Cadastro único">
          <TextInput label="Nome do Edital" source="name" />
          <ReferenceInput
            label="Instituição"
            source="OrganizationId"
            reference={`${permissions.currentAccount.id}/organizations`}
            allowEmpty>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput label="Fluxo Contínuo?" source="continuous" />
          {FoundResearchFormFields(permissions)}
        </FormTab>
        <FormTab label="upload em massa">
          <FileInput
            source="files"
            label="Arquivos do Edital"
            accept="text/csv"
            placeholder={<p>Arraste os arquivo de csv aqui.</p>}>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    )}
  </Create>
);

export default FoundResearchCreate;
