import React, { Component } from 'react';

import { Accordion, Card, Button, Icon, Header, Segment, Statistic } from 'semantic-ui-react';

import ResearchStatistic from './ResearchStatistic';

class ResearchListSummary extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      active: false,
      basePath: this.props.basePath
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { active } = this.state;

    this.setState({ active: !active });
  }

  render() {
    const { active, basePath } = this.state;
    return (
      <Accordion style={{ marginBottom: '3em' }}>
        <Accordion.Title active={active} index={0} onClick={this.handleClick}>
          <Button
            toggle
            active={active}
            style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
            floated="right"
            icon
            onClick={this.getInformation}>
            <Icon name="clipboard outline" />
            Resumo
          </Button>
        </Accordion.Title>
        <Accordion.Content active={active}>
          <div>
            <Card style={{ marginTop: '3em' }} fluid>
              <Card.Content>
                <Segment vertical>
                  <Header>Projetos</Header>
                  <Statistic.Group>
                    <ResearchStatistic
                      type="count"
                      resourcePath={`${basePath}/research_projects`}
                      color="black"
                      text="De Editais Internos"
                      filter={{ type: 'internal' }}
                    />
                    <ResearchStatistic
                      type="count"
                      resourcePath={`${basePath}/research_projects`}
                      color="black"
                      text="De Editais Externos"
                      filter={{ type: 'external' }}
                    />
                    <Statistic horizontal>
                      <Statistic.Value>-</Statistic.Value>
                      <Statistic.Label>De Atividade Voluntária</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </Segment>
              </Card.Content>
            </Card>
          </div>
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default ResearchListSummary;
