export default {
  palette: {
    primary1Color: '#00BFC3',
    primary3Color: '#FFFFFF',
    primary2Color: '#FFFFFF',
    accent1Color: '#C7CFD6',
    canvasColor: '#FFFFFF'
  },
  appBar: {
    color: '#FFFFFF'
  }
};
