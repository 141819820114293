import React from 'react';
import { List } from 'admin-on-rest';
import ResearchFilters from '../common/public-research/filter/index';
import ResearchGrid from '../common/public-research/grid';
import ResearchViewActions from '../common/public-research/view-actions';

export const FeaturedResearchTitle = () => (
  <span>
    <span>Editais Em Destaque</span>
  </span>
);

export const FeaturedResearchList = (props) => (
  <List
    {...props}
    title={<FeaturedResearchTitle {...props} />}
    actions={<ResearchViewActions />}
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<ResearchFilters />}>
    {(permissions) => <ResearchGrid permissions={permissions} {...props} />}
  </List>
);
