import React, { Component } from 'react';

import { DateInput } from 'semantic-ui-calendar-react';
import { Input, Button, Label, Table, Popup } from 'semantic-ui-react';
import { UPDATE, DELETE } from 'admin-on-rest';

import restClient from '../../common/utils/restClient';

class ResearchFinanceItemLine extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      researchId: this.props.researchId,
      financeItem: this.props.financeItem,
      researchProject: this.props.researchProject,
      name: this.props.financeItem.name,
      category: this.props.financeItem.category,
      project: this.props.financeItem.researchProject && this.props.financeItem.researchProject.name,
      value: this.props.financeItem.value,
      id: this.props.financeItem.id,
      basePath: this.props.basePath,
      description: this.props.financeItem.description,
      toBeUpdated: false,
      deleted: false
    };

    if (this.props.financeItem.date) {
      const dateObj = new Date(this.props.financeItem.date);
      this.state.date = `${dateObj.getDate()}-${dateObj.getMonth()}-${dateObj.getFullYear()}`;
    } else {
      this.state.date = null;
    }

    this.updateFinanceItem = this.updateFinanceItem.bind(this);
    this.deleteFinanceItem = this.deleteFinanceItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async updateFinanceItem() {
    const { date, name, value, category, id, researchId, basePath } = this.state;
    let { year, month, day } = {};

    if (date) {
      const dateParams = date.split('-');
      year = dateParams[2];
      month = dateParams[1];
      day = dateParams[0];
    }

    await restClient(UPDATE, `${basePath}/${researchId}/finance_items`, {
      id,
      data: {
        date: new Date(year, month, day),
        name,
        value,
        category
      }
    })
      .then(() => {
        alert('Item atualizado com sucesso!');
        this.setState({
          toBeUpdated: false
        });
        this.props.getFinanceItems();
      })
      .catch(e => {
        console.error(e);
      });
  }

  async deleteFinanceItem() {
    const { id, basePath, researchId } = this.state;

    await restClient(DELETE, `${basePath}/${researchId}/finance_items`, { id })
      .then(() => {
        this.setState({
          deleted: true
        });
        this.props.getFinanceItems();
        alert('Item removido com sucesso!');
      })
      .catch(e => {
        console.error(e);
      });
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({
        [name]: value,
        toBeUpdated: true
      });
    }
  };

  render() {
    const {
      financeItem,
      description,
      deleted,
      date,
      name,
      category,
      project,
      value,
      toBeUpdated,
      researchProject
    } = this.state;
    return deleted ? (
      ''
    ) : (
      <Table.Row title={description}>
        <Table.Cell>
          <DateInput name="date" value={date} onChange={this.handleChange} transparent />
        </Table.Cell>
        {researchProject ? null : (
          <Table.Cell>
            <Input name="project" value={project} transparent />
          </Table.Cell>
        )}
        <Table.Cell>
          <Popup
            key={name}
            trigger={<Input name="name" value={name} onChange={this.handleChange} transparent />}
            header={name}
            content={description}
          />
        </Table.Cell>
        <Table.Cell>
          <Input name="category" value={category} onChange={this.handleChange} transparent />
        </Table.Cell>
        <Table.Cell>
          <Input name="value" labelPosition="right" type="text" value={value} onChange={this.handleChange} transparent>
            <Label color={financeItem.type === 'expense' ? 'red' : 'green'}>R$</Label>
            <input style={{ marginLeft: '5px' }} />
          </Input>
        </Table.Cell>
        <Table.Cell>
          <Button color={toBeUpdated ? 'green' : ''} onClick={this.updateFinanceItem} circular icon="refresh" />
          <Button onClick={this.deleteFinanceItem} circular icon="trash" />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default ResearchFinanceItemLine;
