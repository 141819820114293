import React from 'react';
import { getPermissions, userWithAccess } from '../utils/permissions-utils';
import './styles.css';
import Icon from './Icon';

const supportPhoneNumber = '5548991094954';
const pedroPhoneNumber = '5548988100310';
const amandaPhoneNumber = '5548974009384';

export default () => {
  const { currentAccount } = getPermissions();
  const hasPaidAcces = userWithAccess(currentAccount);
  const whatsappMessage = hasPaidAcces
    ? 'Oi Estou com uma dúvida, você pode me ajudar?'
    : 'Oi, gostaria de saber mais sobre a assinatura da Mettzer.';
  const message = encodeURIComponent(whatsappMessage);
  const hour = new Date().getHours();
  const salesNumber = hour >= 16 ? amandaPhoneNumber : pedroPhoneNumber;
  const phoneNumber = hasPaidAcces ? supportPhoneNumber : salesNumber;

  return (
    <a
      className="whatsapp-button"
      target="_blank"
      href={`https://wa.me/${phoneNumber}?text=${message}`}
      rel="noreferrer">
      <Icon />
    </a>
  );
};
