import React from 'react';
import get from 'lodash.get';

import PropTypes from 'prop-types';

const InceptionTextField = ({ source, record = {} }) => <span>{get(record, source)}</span>;

InceptionTextField.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

InceptionTextField.defaultProps = {
  addLabel: true
};

export default InceptionTextField;
