import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, Filter, TextInput } from 'admin-on-rest';

function TagsFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Busca" source="q" alwaysOn />
    </Filter>
  );
}

// this linter rule was recently broken
// eslint-disable-next-line import/prefer-default-export
export function TagsList(props) {
  return (
    <List title="Lista de Tags" filters={<TagsFilter />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <EditButton label="" />
        <DeleteButton label="" />
      </Datagrid>
    </List>
  );
}
