import React, { Component } from 'react';

import { Button, Icon, Modal, Header, Segment, Statistic } from 'semantic-ui-react';

import ResearchStatistic from './ResearchStatistic';

class ResearchReportModal extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      research: this.props.research,
      basePath: this.props.basePath,
      researchId: this.props.research.id
    };
  }

  render() {
    const { research, basePath, researchId } = this.state;

    return (
      <Modal
        size="fullscreen"
        trigger={
          <Button
            style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
            floated="right"
            icon
            onClick={this.getInformation}>
            <Icon name="clipboard outline" />
          </Button>
        }
        centered
        closeIcon>
        <Modal.Header>{research.name}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <div>
              <Segment vertical>
                <Header>Membros do Edital</Header>
                <Statistic.Group>
                  <ResearchStatistic
                    type="count"
                    resourcePath={`${basePath}/${researchId}/members`}
                    color="black"
                    text="Doutores"
                    filter={{ key: 'dr' }}
                  />
                  <ResearchStatistic
                    type="count"
                    resourcePath={`${basePath}/${researchId}/members`}
                    color="black"
                    text="Mestres"
                    filter={{ key: 'master' }}
                  />
                </Statistic.Group>
              </Segment>
              <Segment vertical>
                <Header>Recursos</Header>
                <Statistic.Group>
                  <ResearchStatistic
                    type="sum"
                    resourcePath={`${basePath}/${researchId}/finance_items`}
                    color="black"
                    text="Total"
                    filter={{ type: 'budget' }}
                  />
                  <ResearchStatistic
                    type="difference"
                    resourcePath={`${basePath}/${researchId}/finance_items`}
                    color="green"
                    text="Disponível"
                    filter={{ type: null }}
                  />

                  <ResearchStatistic
                    type="sum"
                    resourcePath={`${basePath}/${researchId}/finance_items`}
                    color="red"
                    text="Consumido"
                    filter={{ type: 'expense' }}
                  />
                </Statistic.Group>
              </Segment>
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ResearchReportModal;
