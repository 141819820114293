import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import { Notification, setSidebarVisibility } from 'admin-on-rest';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import mettzerTheme from './mettzerTheme';

import AppBar from '../AppBar';

const styles = {
  wrapper: {
    // Avoid IE bug with Flexbox, see #467
    display: 'flex',
    flexDirection: 'column'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  body: {
    backgroundColor: '#C7CFD6',
    display: 'flex',
    flex: 1,
    overflowY: 'hidden',
    overflowX: 'scroll'
  },
  content: {
    flex: 1,
    padding: '2em'
  },
  loader: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 16,
    zIndex: 1200
  }
};

const MettzerLayout = props => {
  const { children, isLoading, title, sidebarVisibility } = props;

  useEffect(() => {
    sidebarVisibility(true);
  }, []);

  return (
    <MuiThemeProvider muiTheme={getMuiTheme(mettzerTheme)}>
      <div style={styles.wrapper}>
        <div style={styles.main}>
          <AppBar title={title} />
          <div className="body" style={styles.body}>
            <div style={styles.content}>{children}</div>
          </div>
          <Notification />
          {isLoading && <CircularProgress color="#fff" size={30} thickness={2} style={styles.loader} />}
        </div>
      </div>
    </MuiThemeProvider>
  );
};

MettzerLayout.propTypes = {
  customRoutes: PropTypes.array,
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  resources: PropTypes.array,
  sidebarVisibility: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });
export default connect(mapStateToProps, {
  sidebarVisibility: setSidebarVisibility
})(MettzerLayout);
