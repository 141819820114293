import React from 'react';

import { Edit, SimpleForm, TextInput } from 'admin-on-rest';

export const GendersEdit = props => (
  <Edit title="Edição de Tags" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome da Tag" source="name" />
    </SimpleForm>
  </Edit>
);
