import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, Icon } from 'semantic-ui-react';

function MettzerSendToNextStageButton(props) {
  const { linkTo } = props;
  return (
    <Button style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }} as={Link} to={linkTo} floated="right">
      <Icon name="paper plane" /> Enviar próxima etapa
    </Button>
  );
}

MettzerSendToNextStageButton.propTypes = {
  linkTo: PropTypes.string
};

export default MettzerSendToNextStageButton;
