export default (permissions, listOfAllowedPermissions) => {
  const { currentAccount: { roles = [] } = {} } = permissions;

  let hasAccess = false;
  roles.forEach(role => {
    if (listOfAllowedPermissions.includes(role)) {
      hasAccess = true;
    }
  });

  return hasAccess;
};
