import React from 'react';

import { Edit, SimpleForm, SelectArrayInput, ReferenceArrayInput, RadioButtonGroupInput } from 'admin-on-rest';

const SearchPreferencesEditTitle = ({ record }) => <span>Preferências para busca de editais:</span>;

export const SearchPreferencesEdit = props => (
  <Edit title={<SearchPreferencesEditTitle />} {...props}>
    {permissions => (
      <SimpleForm redirect="edit">
        <RadioButtonGroupInput
          label="Preferência de resumo de oportunidades por e-mail"
          source="emailSummary"
          choices={[
            { id: 'weekly', name: 'Semanal' },
            { id: 'biweekly', name: 'Quinzenal' },
            { id: 'monthly', name: 'Mensal' },
            { id: 'none', name: 'Nenhuma' }
          ]}
        />
        <ReferenceArrayInput
          label="Grande Áreas"
          source="greatAreaTagIds"
          reference={`${permissions.currentAccount.id}/great_areas`}
          allowEmpty>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Áreas"
          source="areaTagIds"
          reference={`${permissions.currentAccount.id}/areas`}
          allowEmpty>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="SubÁreas"
          source="subAreaTagIds"
          reference={`${permissions.currentAccount.id}/sub_areas`}
          allowEmpty>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Oportunidades de Fomento"
          source="fomentationOppTagIds"
          reference={`${permissions.currentAccount.id}/fomentation_opportunities`}
          allowEmpty>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Tipos de Oportunidades de Fomento"
          source="fomentationTypeTagIds"
          reference={`${permissions.currentAccount.id}/fomentation_types`}
          allowEmpty>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Tags"
          source="tagIds"
          reference={`${permissions.currentAccount.id}/tags`}
          allowEmpty>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    )}
  </Edit>
);
