import React from 'react';

import { Create, SimpleForm, TextInput } from 'admin-on-rest';

export const FeaturedTagsCreate = props => (
  <Create title="Cadastro de Tags" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome da Tag" source="name" />
    </SimpleForm>
  </Create>
);
