import React, { Component } from 'react';

import { Statistic } from 'semantic-ui-react';

import { GET_LIST } from 'admin-on-rest';
import restClient from '../../common/utils/restClient';

class ResearchStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text,
      value: null,
      floated: this.props.floated,
      resourcePath: this.props.resourcePath,
      filter: this.props.filter,
      color: this.props.color,
      type: this.props.type,
      size: this.props.size,
      style: this.props.style,
      update: this.props.update
    };

    this.getValue = this.getValue.bind(this);
  }

  async componentWillMount() {
    this.getValue();
  }

  async getValue() {
    const { resourcePath, filter, type } = this.state;
    await restClient(GET_LIST, `${resourcePath}`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'created_at', order: 'ASC' },
      filter
    })
      .then(response => {
        if (type === 'count') {
          this.setState({
            value: response.total.toString()
          });
        }
        if (type === 'sum') {
          this.setState({
            value: `R$ ${response.data
              .map(item => (item.value ? item.value : 0))
              .reduce((acc, value) => parseFloat(acc) + parseFloat(value))
              .toString()}`
          });
        }
        if (type === 'difference') {
          const value = response.data
            .map(item => {
              if (item.value) {
                return item.type === 'expense' ? -1 * item.value : item.value;
              }
              return 0;
            })
            .reduce((acc, newValue) => parseFloat(acc) + parseFloat(newValue));
          if (value < 0) {
            this.state.color = 'red';
          }
          this.setState({
            value: `R$ ${value.toString()}`
          });
        }
      })
      .catch(e => {
        console.error(e);
      });
  }

  render() {
    const { value, text, color, size, floated, style, update } = this.state;
    console.log(update);
    return (
      <Statistic style={style} floated={floated} size={size} horizontal color={color} update={update}>
        <Statistic.Value>{value || '-'}</Statistic.Value>
        <Statistic.Label>{text}</Statistic.Label>
      </Statistic>
    );
  }
}

export default ResearchStatistic;
