import React, { useState, useEffect } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';
import apiClient from '../utils/apiClient';

const adjustTags = (tags) =>
  tags.map((tag) => ({
    key: tag.id,
    text: tag.name,
    value: tag.id
  }));

const getTags = async (accountHash, tagType, searchQuery = '') => {
  const response = await apiClient.authenticatedGet({
    resource: `${accountHash}/${tagType}`,
    queryParams: {
      q: searchQuery
    }
  });
  return adjustTags(response.data);
};

const TagsSelect = (props) => {
  const { tagType, multiple, placeholder, onChange, accountHash } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [value, setValue] = useState(props.value);
  const [tags, setTags] = useState([]);

  const fetchOptions = async () => {
    setIsFetching(true);
    setTags(await getTags(accountHash, tagType, searchQuery));
    setIsFetching(false);
  };

  useEffect(() => {
    fetchOptions();
  }, [tagType]);

  const handleChange = (e, { value: pValue }) => {
    onChange(e, { value: pValue });
    setValue(pValue);
  };

  const handleSearchChange = (e, { searchQuery: pSearchQuery }) => {
    setSearchQuery(pSearchQuery);
    fetchOptions();
  };

  return (
    <Popup
      trigger={
        <Dropdown
          fluid
          selection
          multiple={multiple}
          search
          options={tags}
          value={value || []}
          placeholder={placeholder}
          onChange={handleChange}
          onSearchChange={handleSearchChange}
          disabled={isFetching}
          loading={isFetching}
          inverted
        />
      }
      content="Digite para buscar e/ou clique para selecionar."
      on="focus"
      position="right center"
    />
  );
};

export default TagsSelect;
