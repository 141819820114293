import React, { useEffect } from 'react';
import { userLogin as userLoginAction } from 'admin-on-rest';
import { connect } from 'react-redux';
import { findPermissions } from '../authClient';

const Auth = (props) => {
  const { location: { search = '' } = {}, userLogin } = props;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const redirect = params.get('redirect');

  const loginUser = async ({ redirectUrl }) => {
    const perms = await findPermissions(token).catch(() => null);
    let calcRedirect = redirectUrl;
    if (perms && redirectUrl) {
      calcRedirect = `/${perms.currentAccount.id}${calcRedirect}`;
    }
    userLogin(token, calcRedirect);
  };
  useEffect(() => {
    loginUser({ redirectUrl: redirect });
  }, [token]);

  return <div>Autenticando o usuário...</div>;
};

export default connect(null, { userLogin: userLoginAction })(Auth);
