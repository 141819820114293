import React, { Component } from 'react';

import { DateInput } from 'semantic-ui-calendar-react';

import {
  Dropdown,
  Input,
  Grid,
  Button,
  Modal,
  Header,
  Table,
  Radio,
  TextArea,
  Form,
  Statistic
} from 'semantic-ui-react';
import { CREATE, GET_LIST } from 'admin-on-rest';

import ResearchFinanceItemLine from './ResearchFinanceItemLine';

import restClient from '../../common/utils/restClient';

class ResearchFinanceItemsTable extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      researchProject: this.props.researchProject,
      basePath: this.props.basePath,
      researchId: this.props.research.id,
      researchProjectId: this.props.researchProject && this.props.researchProject.id,
      header: this.props.header,
      type: this.props.type,
      financeItems: [],
      newDate: null,
      newName: null,
      newProject: null,
      newCategory: null,
      newValue: null,
      newDescription: null,
      forResearchOnly: false,
      researchProjects: [],
      value: null
    };

    this.getFinanceItems = this.getFinanceItems.bind(this);
    if (!this.props.researchProject) {
      this.getResearchProjectNames = this.getResearchProjectNames.bind(this);
    }
    this.addFinanceItem = this.addFinanceItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cleanForm = this.cleanForm.bind(this);
  }

  componentDidMount() {
    this.getFinanceItems();
  }

  async getResearchProjectNames() {
    const { basePath, researchId } = this.state;

    this.setState({
      researchProjects: []
    });

    await restClient(GET_LIST, `${basePath}/${researchId}/research_projects`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'created_at', order: 'ASC' }
    })
      .then(response => {
        this.setState({
          researchProjects: response.data
        });
      })
      .catch(e => {
        console.error(e);
      });
  }

  async getFinanceItems() {
    const { basePath, researchId, researchProjectId, type } = this.state;

    this.setState({
      financeItems: []
    });

    await restClient(GET_LIST, `${basePath}/${researchId}/finance_items`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'created_at', order: 'ASC' },
      filter: { type, researchProjectId }
    })
      .then(response => {
        this.setState({
          financeItems: response.data,
          value: `R$ ${response.data
            .map(item => (item.value ? item.value : 0))
            .reduce((acc, value) => parseFloat(acc) + parseFloat(value))
            .toString()}`
        });
        this.props.update();
      })
      .catch(e => {
        console.error(e);
      });
  }

  async addFinanceItem() {
    const {
      basePath,
      type,
      newDate,
      newName,
      newCategory,
      newDescription,
      newValue,
      forResearchOnly,
      researchId,
      researchProjectId,
      newProject
    } = this.state;
    let { year, month, day } = {};

    if (newDate) {
      [day, month, year] = newDate.split('-');
    }

    await restClient(CREATE, `${basePath}/${researchId}/finance_items`, {
      data: {
        ResearchProjectId: researchProjectId || (forResearchOnly ? null : newProject),
        ResearchId: researchProjectId ? null : forResearchOnly ? researchId : null,
        name: newName,
        date: new Date(year, month, day),
        description: newDescription,
        value: newValue,
        type,
        category: newCategory
      }
    })
      .then(() => {
        alert('Item criado com sucesso!');
        this.getFinanceItems();
      })
      .catch(e => {
        alert('Não foi possível criar o elemento, tente novamente mais tarde!');
        console.error(e);
      });
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({
        [name]: value
      });
    }
  };

  cleanForm() {
    this.setState({
      newDate: null,
      newName: null,
      newProject: null,
      newCategory: null,
      newValue: null,
      newDescription: null,
      forResearchOnly: false
    });
    if (!this.props.researchProject) {
      this.getResearchProjectNames();
    }
  }

  render() {
    const {
      researchProjects,
      forResearchOnly,
      financeItems,
      basePath,
      researchId,
      researchProjectId,
      researchProject,
      newDate,
      newName,
      newProject,
      newCategory,
      newValue,
      type,
      newDescription,
      header,
      value
    } = this.state;

    return (
      <div>
        <Header>{header}</Header>
        <Table basic="very" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data</Table.HeaderCell>
              {researchProjectId ? null : <Table.HeaderCell>Projeto</Table.HeaderCell>}
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Categoria</Table.HeaderCell>
              <Table.HeaderCell>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {financeItems.map(item => (
              <ResearchFinanceItemLine
                researchProject={researchProject}
                getFinanceItems={this.getFinanceItems.bind(this)}
                financeItem={item}
                basePath={basePath}
                researchId={researchId}
              />
            ))}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={researchProjectId ? '3' : '4'}>
                <Modal
                  size="small"
                  trigger={
                    <Button
                      style={{ backgroundColor: '#5FCBCF', color: '#FFFFFF' }}
                      floated="left"
                      icon="add"
                      primary
                      onClick={this.cleanForm}
                    />
                  }
                  closeIcon>
                  <Modal.Header>Adicionar Item Financeiro de {header}</Modal.Header>
                  <Modal.Content>
                    <Grid columns={2}>
                      {researchProjectId ? null : (
                        <Grid.Column>
                          <Radio
                            label="Edital"
                            name="forResearchOnly"
                            value
                            checked={this.state.forResearchOnly === true}
                            onChange={this.handleChange}
                            style={{ margin: '5px' }}
                          />
                          <Radio
                            style={{ margin: '5px' }}
                            label="Projeto"
                            name="forResearchOnly"
                            value={false}
                            checked={this.state.forResearchOnly === false}
                            onChange={this.handleChange}
                          />
                        </Grid.Column>
                      )}
                      {researchProjectId ? null : (
                        <Grid.Column>
                          <Dropdown
                            fluid
                            search
                            selection
                            label="Projeto de Pesquisa: "
                            placeholder="Projeto"
                            name="newProject"
                            value={newProject}
                            onChange={this.handleChange}
                            options={researchProjects.map(project => ({
                              key: project.id,
                              value: project.id,
                              text: project.name
                            }))}
                            disabled={forResearchOnly}
                          />
                        </Grid.Column>
                      )}
                      <Grid.Column>
                        <Input
                          label="Nome do Item: "
                          placeholder="Nome"
                          name="newName"
                          value={newName}
                          onChange={this.handleChange}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DateInput
                          placeholder="Data"
                          name="newDate"
                          value={newDate}
                          onChange={this.handleChange}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Input
                          label="Categoria: "
                          placeholder="Categoria"
                          name="newCategory"
                          value={newCategory}
                          onChange={this.handleChange}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Input
                          label="Valor: "
                          placeholder="Valor"
                          name="newValue"
                          value={newValue}
                          onChange={this.handleChange}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form>
                          <TextArea
                            label="Descrição: "
                            placeholder="Descrição do item"
                            name="newDescription"
                            value={newDescription}
                            onChange={this.handleChange}
                            fluid
                          />
                        </Form>
                      </Grid.Column>
                      <Grid.Column />
                    </Grid>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button icon="check" content="Adicionar" onClick={this.addFinanceItem} />
                  </Modal.Actions>
                </Modal>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Statistic size="mini" horizontal color={type === 'budget' ? 'green' : 'red'}>
                  <Statistic.Value>{value || '-'}</Statistic.Value>
                  <Statistic.Label>Total</Statistic.Label>
                </Statistic>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}

export default ResearchFinanceItemsTable;
