import React from 'react';
import { Edit, DisabledInput, FormTab, FileInput, FileField, RadioButtonGroupInput, UPDATE } from 'admin-on-rest';

import { MettzerTabbedForm } from '../common/elements/TabbedForm';
import CommentsBox from '../common/elements/CommentsBox';
import StageCompletionUsersCard from '../common/elements/StageCompletionUsersCard';

const StageTitle = ({ record }) => (
  <div>
    <span>Etapa {record ? `${record.stage.name}` : ``}</span>
    <span> do projeto {record ? `${record.researchProject.name}` : ``}</span>
  </div>
);

export const StageCompletionEdit = (props) => (
  <div>
    <Edit title={<StageTitle />} {...props}>
      {(permissions) => (
        <MettzerTabbedForm
          history={props.history}
          redirectResource={`${permissions.currentAccount.id}/ongoing_researches`}
          event={UPDATE}
          crudUpdate={props.crudUpdate}>
          <FormTab label="Informações Gerais">
            <DisabledInput source="id" />
            <RadioButtonGroupInput
              source="status"
              optionText="name"
              optionValue="value"
              choices={[
                { value: 'unstarted', name: 'Não iniciada' },
                { value: 'pending', name: 'Pendente' },
                { value: 'completed', name: 'Completa' }
              ]}
            />
            {/* <RichTextInput label="Comentários" source="comments" /> */}
          </FormTab>
          <FormTab label="Arquivos">
            <FileInput
              source="files"
              label=""
              accept="application/pdf"
              placeholder={<p>Arraste os arquivos desejados aqui.</p>}
              multiple>
              <FileField source="src" title="title" />
            </FileInput>
          </FormTab>
        </MettzerTabbedForm>
      )}
    </Edit>
    <StageCompletionUsersCard {...props} />
    <CommentsBox {...props} />
  </div>
);

export default {};
