import React from 'react';

import { Create, SimpleForm, TextInput } from 'admin-on-rest';

export const GreatAreasCreate = props => (
  <Create title="Cadastro de Grandes Áreas" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome da Grande Área" source="name" />
    </SimpleForm>
  </Create>
);
