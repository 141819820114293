import React from 'react';
import PropTypes from 'prop-types';
import { CardActions } from 'material-ui/Card';
import { ListButton } from 'admin-on-rest';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const FoundResearchCreateActions = ({ basePath }) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath} />
  </CardActions>
);

FoundResearchCreateActions.propTypes = {
  basePath: PropTypes.string.isRequired
};

export default FoundResearchCreateActions;
