import React from 'react';
import { List, Datagrid, TextField } from 'admin-on-rest';

export const StageCompletionList = props => (
  <List title="Todas etapas de projetos" {...props}>
    <Datagrid>
      <TextField source="researchProject.name" label="Projeto" />
      <TextField source="stage.name" label="Etapa" />
      <TextField source="status" label="Status" />
    </Datagrid>
  </List>
);
